import React from 'react';
import {Container} from "reactstrap";
import TermsOfUse from "../../components/Common/TermsOfUse";
import { useHistory } from 'react-router-dom';

const Terms = (props) => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <div className="text-center mb-4">
                        <button className="btn email-back" onClick={() => history.goBack()}>
                            &lt;&nbsp;&nbsp;<u>BACK</u>
                        </button>
                    </div>
                    <TermsOfUse hasBackButton={true} />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Terms;