import React from "react";
import { Container } from "reactstrap";
import AddNewImage from "../../assets/images/ListPlusBig.png";
import DosingPageHead from "../../components/Common/DosingPageHead";
import DosingSelector from "../../components/DosingSelector";


const AddNewPlan = () => {
    return (
        <div className="page-content">
            <Container>
                <DosingPageHead image={AddNewImage} text={"Add a dosing plan"}/>
                <DosingSelector />
            </Container>
        </div>
    )
}

export default AddNewPlan;
