import React  from "react";
import Logo from "../../assets/images/logo.png";

import { Container } from "reactstrap";

const Header = () => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container>
            <div className="d-flex float-right">
              <img src={Logo} alt="" height="37" />
            </div>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header