import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import '../src/i18n';
import store from "../src/store";
import App from '../src/App';
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.body.appendChild(document.createElement('div')),
  )
})
