import React, { useState, useEffect } from 'react';
import {Col, Container, Row} from "reactstrap";
import DosingPageHead from "../Common/DosingPageHead";
import ListImage from "../../assets/images/ListBig.png";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Form, FormikProvider, useFormik} from "formik";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import * as Yup from 'yup';
import {makeStyles} from "@material-ui/core/styles";
import {paramPost} from "../../apiHelpers/commonApiHelper";
import SuccessDialog from "../Modal/SuccessModal";

const emailSender = (props) => {
    const {planInfo, planDetail} = props;

    const [isEmailSent, setIsEmailSent] = useState(false)

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            textAlign: 'center'
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '38ch',
        },
    }));

    const Schema = Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            isCopy: false,
            emailCopy: ""
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            const params = {
                uid: props.planInfo.uid,
                email: values.email,
                email_cc: values.emailCopy
            }
            paramPost('/api/v1/plans/send_plan_email', params)
                .then(res => {
                    if(res.status === 200) {
                        setIsEmailSent(!isEmailSent)
                    }
                })
                .catch(err => {
                    throw err;
                })
        },
    });

    const goBackToIndex = () => {
        props.changeViewStatus(1)
    }

    const { errors, touched, handleSubmit, getFieldProps, isValid, dirty, values, setFieldValue } = formik;
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <DosingPageHead image={ListImage} text={"Dosing plan"}/>
                    <Row className="justify-content-center">
                        <FormikProvider value={formik}>
                            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Col xs={12}>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Email address"
                                        variant="outlined"
                                        color={"primary"}
                                        {...getFieldProps('email')}
                                        className={clsx(classes.margin, classes.textField)}
                                        placeholder={"Enter your email address"}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormControlLabel
                                        checked={values.isCopy}
                                        control={<Checkbox color="primary" />}
                                        label="Email a copy to my practice?"
                                        labelPlacement="start"
                                        onChange={(e) => setFieldValue("isCopy", e.target.checked)}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Email copy"
                                        variant="outlined"
                                        color={"primary"}
                                        disabled={!values.isCopy}
                                        {...getFieldProps('emailCopy')}
                                        className={clsx(classes.margin, classes.textField)}
                                        placeholder={"Enter email address"}
                                    />
                                </Col>
                                <Col xs={12} className="next-btn-wrapper justify-content-center text-center">
                                    <button type="button" className="btn back-btn mr-2" onClick={goBackToIndex}>BACK</button>
                                    <button type="submit" disabled={!((isValid && dirty) || props.isBack)}
                                            className={`${(isValid && dirty) ? "activated-next-btn" : "disabled-next-btn"} btn ml-2`}>
                                        SEND THE PLAN
                                    </button>
                                </Col>
                            </Form>
                        </FormikProvider>
                    </Row>
                </Container>
            </div>
            {isEmailSent&& (
                <SuccessDialog
                    onClose={() => {
                        setIsEmailSent(!isEmailSent);
                        props.changeViewStatus(1);
                    }}
                    open={isEmailSent}
                    title={"Email has been sent"}
                    content={"OK"}/>
            )}
        </React.Fragment>
    )
}
export default emailSender;