import React, {useState} from "react";
import {Container} from "reactstrap";
import DosingCalculator from "../assets/images/dosing_calculator.png";
import DosingPageHead from "../components/Common/DosingPageHead";
import StepOne from "../components/DosingCalculator/StepOne";
import StepTwo from "../components/DosingCalculator/StepTwo";

const Calculator = (props) => {
    const [stepProgress, setStepProgress] = useState(1);
    const [isBack, setIsBack] = useState(false);
    const [weight, setWeight]=  useState('')

    const [stepOneValues, setStepOneValues] = useState({
        lastKnownWeight: 0,
        dateOfLastKnownWeight: null,
        estimatedWeightGain: 1,
        treatmentDate: null,
    });

    const [stepTwoValues, setStepTwoValues] = React.useState({
        totalAnimals: 0,
        treatmentProduct: 1,
    });

    const handleStepOne = (values) => {
        if(stepTwoValues.totalAnimals === 0) setIsBack(false);
        setStepOneValues({...values});
        setStepProgress(2);
    }

    const handleStepTwo = (values) => {
        setIsBack(true);
        setStepTwoValues({...values});
        setStepProgress(2);
    }

    const handleBack = (value) => {
        setStepProgress(value);
        setIsBack(true)
    }

    const setTotalWeight = (value) => {
        setWeight(value)
    }

    return (
        <div className="page-content">
            <Container>
                <DosingPageHead image={DosingCalculator} text={"Product dose calculator"} />
                {stepProgress === 1 && (
                    <StepOne values={stepOneValues} expectedWeight={weight} submitStepOne={handleStepOne} setExpectedWeight={setTotalWeight} isBack={isBack} />
                )}
                {stepProgress === 2 && (
                    <StepTwo values={stepTwoValues} expectedWeight={weight} submitStepTwo={handleStepTwo} isBack={isBack} goBack={handleBack} />
                )}
            </Container>
        </div>
    )
}

export default Calculator;