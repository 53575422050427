import React from "react";
import PropTypes from 'prop-types';

const DosingPageHead = (props) => {
    const { image, text } = props;
    return (
        <div className="dosing-header-wrapper text-center">
            <img className="dosing-header-image" src={image} width={56} alt={"header-img"}/>
            <p className="dosing-header-title" >
                {text}
            </p>
        </div>
    )
}

DosingPageHead.propTypes = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default DosingPageHead;
