import React, {Fragment, useState} from "react";
import {Row, Col} from "reactstrap";
import {makeStyles} from '@material-ui/core/styles';
import IntroDialog from "../Modal/IntroModal";
import clsx from 'clsx';
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FormHelperText} from "@material-ui/core"
import {Link} from "react-router-dom";
import moment from "moment";
import { useHistory } from 'react-router-dom';

const dialogConst = {
    title: "VCI number",
    content: "Registration number with Veterinary Council of Ireland in numbers only e.g. 21321"
}


const StepOne = (props) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const Schema = Yup.object().shape({
        lastKnownWeight: Yup.number().min(0).required('This field is required'),
        dateOfLastKnownWeight: Yup.date().required('This field is required'),
        estimatedWeightGain: Yup.number()
            .test("validation", "Select one option", (value) => value !== 1)
            .required('This field is required'),
        treatmentDate: Yup.date().required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            lastKnownWeight: props.values.lastKnownWeight,
            dateOfLastKnownWeight: props.values.dateOfLastKnownWeight,
            estimatedWeightGain: props.values.estimatedWeightGain,
            treatmentDate: props.values.treatmentDate,
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            props.submitStepOne(values);
        },
    });

    const handleClose = () => {
        setOpen(!open);
    }

    const selectOptions = () => {
        let options = [0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2];
        return options.map((item, index) => {
            return (
                <MenuItem value={item*10} key={index}>{item}</MenuItem>
            )
        })
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            justifyContent: "center"
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '38ch',
        },
    }));

    const calculateWeight = (values) => {
        const endDate = moment(values.treatmentDate);
        const startDate = moment(values.dateOfLastKnownWeight);
        const diff = endDate.diff(startDate, 'days');
        const expectedWeight = Math.round((diff * (values.estimatedWeightGain/10) + values.lastKnownWeight));
        console.log((diff * (values.estimatedWeightGain/10) + values.lastKnownWeight).toFixed(2))
        props.setExpectedWeight(expectedWeight);
        return expectedWeight;
    }
    const classes = useStyles();
    const {errors, touched, handleSubmit, getFieldProps, isValid, dirty, setFieldValue, values} = formik;
    return (
        <Fragment>
            <FormikProvider value={formik}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Row className={classes.root}>
                            <div className="time-wrapper">
                                <TextField
                                    id="outlined-disabled"
                                    label="Last known weight in kg"
                                    type="number"
                                    variant="outlined"
                                    {...getFieldProps('lastKnownWeight')}
                                    color={"primary"}
                                    className={clsx(classes.margin, classes.textField)}
                                    placeholder={"Last known weight"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                    }}
                                    error={Boolean(touched.lastKnownWeight && errors.lastKnownWeight)}
                                    helperText={touched.lastKnownWeight && errors.lastKnownWeight}
                                />
                            </div>
                            <div className="time-wrapper">
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    className={clsx(classes.margin, classes.textField)}
                                    inputVariant="outlined"
                                    value={values.dateOfLastKnownWeight}
                                    label="Date of last known weight"
                                    format="dd/MM/yyyy"
                                    InputAdornmentProps={{position: "start"}}
                                    onChange={(newValue, value) => {
                                        setFieldValue('dateOfLastKnownWeight', newValue)
                                    }}
                                    placeholder={"dd/mm/yyyy"}
                                    error={Boolean(touched.dateOfLastKnownWeight && errors.dateOfLastKnownWeight)}
                                    helperText={touched.dateOfLastKnownWeight && errors.dateOfLastKnownWeight}
                                />
                            </div>
                        </Row>
                        <Row className={classes.root}>
                            <div className="time-wrapper">
                                <FormControl
                                    variant="outlined"
                                    error={Boolean(touched.estimatedWeightGain && errors.estimatedWeightGain)}
                                    className={clsx(classes.margin, classes.textField)}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Estimated Daily Live Weight Gain
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Estimated Daily Live Weight Gain"
                                        {...getFieldProps('estimatedWeightGain')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            Select from list
                                        </MenuItem>
                                        {selectOptions()}
                                    </Select>
                                    <FormHelperText>{touched.estimatedWeightGain && errors.estimatedWeightGain}</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="time-wrapper">
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    className={clsx(classes.margin, classes.textField)}
                                    inputVariant="outlined"
                                    value={values.treatmentDate}
                                    label="Treatment date"
                                    format="dd/MM/yyyy"
                                    minDate={values.dateOfLastKnownWeight}
                                    InputAdornmentProps={{position: "start"}}
                                    onChange={(newValue, value) => {
                                        setFieldValue('treatmentDate', newValue)
                                    }}
                                    placeholder={"dd/mm/yyyy"}
                                    error={Boolean(touched.treatmentDate && errors.treatmentDate)}
                                    helperText={touched.treatmentDate && errors.treatmentDate}
                                />
                            </div>
                        </Row>
                        <Row className={classes.root}>
                            <p className="calculator-result">
                                Expected weight of animal on treatment date = &nbsp;
                                {(isValid && dirty && values.treatmentDate && values.dateOfLastKnownWeight) || props.isBack ? calculateWeight(values) + "Kg" : "Kg"}
                            </p>
                        </Row>
                        <Row className="next-btn-wrapper justify-content-center">
                            <button onClick={() => history.goBack()} className="btn back-btn mr-4">BACK</button>
                            <button disabled={!((isValid && dirty) || props.isBack)} type="submit"
                                    className={`${(isValid && dirty) || props.isBack ? "activated-next-btn" : "disabled-next-btn"} btn ml-4`}>NEXT
                            </button>
                        </Row>
                        <Row className={classes.root}>
                            <div className="glossary-link">
                                <Link to="/glossary" className="glossary-letter">VIEW GLOSSARY</Link>
                            </div>
                        </Row>
                        {open && (
                            <IntroDialog onClose={handleClose} open={open} title={dialogConst.title}
                                         content={dialogConst.content}/>
                        )}
                    </Form>
                </MuiPickersUtilsProvider>
            </FormikProvider>
        </Fragment>
    )
}

export default StepOne;