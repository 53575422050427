import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '365px',
        margin: "auto",
    },
    dialog: {
        padding: "0!important"
    },
    title: {
        padding: "24px 24px 0",
        fontSize: "16px",
        color: "#777777",
        textAlign: "center"
    },
    content: {
        padding: "19px",
        fontSize: "14px",
        textAlign: "center"
    }
}));

const YesOrNoConfirmModal = (props) => {
    const { onClose, open, title } = props;
    const classes = useStyles();

    const handleClose = (val) => {
        onClose(val);
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
            <DialogContent className={classes.dialog}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.content}>
                    <button className="btn mr-3" onClick={() => handleClose(false)}>
                        <h5 style={{color: "#FF6400"}} className="mb-0">NO</h5>
                    </button>
                    <button className="btn mr-3" onClick={() => handleClose(true)}>
                        <h5 style={{color: "#FF6400"}} className="mb-0">YES</h5>
                    </button>
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

YesOrNoConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default YesOrNoConfirmModal;