import React, {useState} from 'react';
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";

import 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {makeStyles} from '@material-ui/core/styles';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import Step1 from "../../assets/images/step1.png";
import Step2 from "../../assets/images/step2.png";
import Step3 from "../../assets/images/step3.png";
import Step4 from "../../assets/images/step4.png";
import Step5 from "../../assets/images/step5.png";
import Step6 from "../../assets/images/step6.png";
import farmerAvatar from "../../assets/images/farmerAvatar.png";
import {paramGet, paramPost} from "../../apiHelpers/commonApiHelper";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F96638'
        }
    }
});


const Dashboard = (props) => {
    const classes = useStyles();
    //For steps
    const [step, setStep] = useState(1);
    //For step 1
    const [animalType, setAnimalType] = useState(1);
    const [seasonType, setSeasonType] = useState(1);
    //For step 2
    const [labour, setLabour] = useState(-1);
    const [wormer, setWormer] = useState(-1);
    const [stock, setStock] = useState(-1);
    //For step 3
    const [turnout, setTurnOut] = useState(null);
    const [housing, setHousing] = useState(null);

    const handleStepOne = (value, type) => {
        if (type === 1) {
            setAnimalType(parseInt(value))
        }
        if (type === 2) {
            setSeasonType(parseInt(value))
        }
    }

    const goToNext = (value) => {
        if (value === 0) {
            setStep(2);
        }
    }

    const createPlan = () => {
        const params = {
            animal: animalType,
            season: seasonType,
            labour,
            wormer,
            stock,
            turn_out: turnout,
            housing
        }
        paramPost('/api/v1/dosing_plan', params)
            .then(res => {
                if (res.status === 201) {
                    window.location.href = `/dosing_review/${res.data.uid}`
                }
            })
            .catch(err => {
                throw err
            })
    }

    const stepper = () => {
        switch (step) {
            case 1:
                if (animalType !== 1 && seasonType !== 1) {
                    return (
                        <img src={Step2} alt="Stepper"/>
                    )
                } else {
                    return (
                        <img src={Step1} alt="Stepper"/>
                    )
                }
            case 2:
                if (((animalType === 4 && seasonType === 2) && labour !== -1 && wormer !== -1) || (labour !== -1 && wormer !== -1 && stock !== -1)) {
                    return (
                        <img src={Step4} alt="Stepper"/>
                    )
                } else {
                    return (
                        <img src={Step3} alt="Stepper"/>
                    )
                }
            case 3:
                if (turnout && housing) {
                    return (
                        <img src={Step6} alt="Stepper"/>
                    )
                } else {
                    return (
                        <img src={Step5} alt="Stepper"/>
                    )
                }
            default:
                break;
        }
    }

    return (
        <Row className="mt-2">
            <Col xs={12} className="text-center">
                <p className="font-size-14" style={{marginBottom: "10px"}}>
                    Step {step} of 3
                </p>
                {stepper()}
                <div className="mt-4"/>
                <MuiThemeProvider theme={theme}>
                    {step === 1 && (
                        <React.Fragment>
                            <p className="font-size-15 mb-3">
                                Choose an animal type and grazing season to create a plan
                            </p>
                            <div>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="animal_type">Animal type</InputLabel>
                                    <Select
                                        native
                                        value={animalType}
                                        onChange={(e) => handleStepOne(e.target.value, 1)}
                                        label="Animal type"
                                        inputProps={{
                                            name: 'animal_type',
                                            id: 'animal_type',
                                        }}
                                    >
                                        <option value={1} disabled={true}>
                                            Select an animal type
                                        </option>
                                        <option value={2}>Dairy</option>
                                        <option value={3}>Dairy calf to beef</option>
                                        <option value={4}>Beef spring born suckler</option>
                                        <option value={5}>Beef autumn born suckler</option>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel htmlFor="grazing_season">Grazing season</InputLabel>
                                    <Select
                                        native
                                        value={seasonType}
                                        onChange={(e) => handleStepOne(e.target.value, 2)}
                                        label="Grazing season"
                                        inputProps={{
                                            name: 'grazing_season',
                                            id: 'grazing_season',
                                        }}
                                    >
                                        <option value={1} disabled={true}>
                                            Select a grazing season
                                        </option>
                                        <option value={2}>First season grazing</option>
                                        <option value={3}>Second season grazing</option>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="next-btn-wrapper">
                                {animalType !== 1 && seasonType !== 1 ? (
                                    <button className="btn activated-next-btn" onClick={() => goToNext(0)}>Next</button>
                                ) : (
                                    <button className="btn disabled-next-btn" disabled={true}>Next</button>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    {step === 2 && (
                        <React.Fragment>
                            <p className="font-size-15 mb-5">
                                Answer the following questions
                            </p>
                            {animalType === 4 && seasonType === 2 ? (
                                <Row className="content-wrapper">
                                    <Col xs={12} sm={6} className="mb-3">
                                        <div className="question-wrapper">
                                            <h5>Labour</h5>
                                            <p>
                                                Is help available to gather stock <br/>
                                                multiple times throughout
                                                the grazing season?
                                            </p>
                                            <div>
                                                <button onClick={() => setLabour(1)}
                                                        className={`btn ${labour === 1 ? "activated-yes-btn" : "disabled-yes-btn"}`}>Yes
                                                </button>
                                                <button onClick={() => setLabour(0)}
                                                        className={`btn ${labour === 0 ? "activated-no-btn" : "disabled-no-btn"}`}>No
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} className="mb-3">
                                        <div className="question-wrapper">
                                            <h5>Wormer resistance</h5>
                                            <p>
                                                Are you concerned about wormer resistance?
                                            </p>
                                            <div>
                                                <div>
                                                    <button onClick={() => setWormer(1)}
                                                            className={`btn ${wormer === 1 ? "activated-yes-btn" : "disabled-yes-btn"}`}>Yes
                                                    </button>
                                                    <button onClick={() => setWormer(0)}
                                                            className={`btn ${wormer === 0 ? "activated-no-btn" : "disabled-no-btn"}`}>No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="content-wrapper">
                                    <Col xs={12} sm={4} className="mb-3">
                                        <div className="question-wrapper">
                                            <h5>Labour</h5>
                                            <p>
                                                Is help available to gather stock
                                                multiple times throughout
                                                the grazing season?
                                            </p>
                                            <div>
                                                <button onClick={() => setLabour(1)}
                                                        className={`btn ${labour === 1 ? "activated-yes-btn" : "disabled-yes-btn"}`}>Yes
                                                </button>
                                                <button onClick={() => setLabour(0)}
                                                        className={`btn ${labour === 0 ? "activated-no-btn" : "disabled-no-btn"}`}>No
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4} className="mb-3">
                                        <div className="question-wrapper">
                                            <h5>Wormer resistance</h5>
                                            <p>
                                                Are you concerned about wormer resistance?
                                            </p>
                                            <div>
                                                <div>
                                                    <button onClick={() => setWormer(1)}
                                                            className={`btn ${wormer === 1 ? "activated-yes-btn" : "disabled-yes-btn"}`}>Yes
                                                    </button>
                                                    <button onClick={() => setWormer(0)}
                                                            className={`btn ${wormer === 0 ? "activated-no-btn" : "disabled-no-btn"}`}>No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4} className="mb-3">
                                        <div className="question-wrapper">
                                            <h5>Set stock</h5>
                                            <p>
                                                Are you able to set stock
                                                throughout the season?
                                            </p>
                                            <div>
                                                <button onClick={() => setStock(1)}
                                                        className={`btn ${stock === 1 ? "activated-yes-btn" : "disabled-yes-btn"}`}>Yes
                                                </button>
                                                <button onClick={() => setStock(0)}
                                                        className={`btn ${stock === 0 ? "activated-no-btn" : "disabled-no-btn"}`}>No
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <div className="next-btn-wrapper">
                                <button className="btn back-btn mr-3" onClick={() => setStep(1)}>Back</button>
                                {((animalType === 4 && seasonType === 2) && labour !== -1 && wormer !== -1) || (labour !== -1 && wormer !== -1 && stock !== -1) ? (
                                    <button className="btn activated-next-btn ml-3"
                                            onClick={() => setStep(3)}>Next</button>
                                ) : (
                                    <button className="btn disabled-next-btn ml-3" disabled={true}>Next</button>
                                )}
                            </div>
                        </React.Fragment>
                    )}

                    {step === 3 && (
                        <React.Fragment>
                            <p className="font-size-15 mb-5">
                                Set a turn-out date and the expected housing date
                            </p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Row className="justify-content-center">
                                    <div className="time-wrapper">
                                        <p className="font-size-15">
                                            Set a turn-out date
                                        </p>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Enter date"
                                            format="dd/MM/yyyy"
                                            value={turnout}
                                            InputAdornmentProps={{position: "start"}}
                                            onChange={date => setTurnOut(date.valueOf())}
                                            placeholder={"dd/mm/yyyy"}
                                        />
                                    </div>
                                    <div className="time-wrapper">
                                        <p className="font-size-15">
                                            Set the expected housing date
                                        </p>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Enter date"
                                            format="dd/MM/yyyy"
                                            value={housing}
                                            InputAdornmentProps={{position: "start"}}
                                            onChange={date => setHousing(date.valueOf())}
                                            placeholder={"dd/mm/yyyy"}
                                        />
                                    </div>
                                </Row>
                            </MuiPickersUtilsProvider>
                            <div className="next-btn-wrapper">
                                <button className="btn back-btn mr-3" onClick={() => setStep(2)}>Back</button>
                                {turnout && housing ? (
                                    <button className="btn activated-next-btn ml-3"
                                            onClick={createPlan}
                                    >Next</button>
                                ) : (
                                    <button className="btn disabled-next-btn ml-3" disabled={true}>Next</button>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </MuiThemeProvider>
                <div className="glossary-link">
                    <Link to="/glossary" className="glossary-letter">VIEW GLOSSARY</Link>
                </div>
            </Col>
        </Row>
    );
}

export default Dashboard;
