import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '365px',
        margin: "auto",
    },
    dialog: {
        border: "1px solid #F96638",
        borderRadius: 0,
        boxShadow: "unset",
        padding: "0!important"
    },
    close: {
        padding: "6px 12px"
    },
    title: {
        padding: "12px 38px",
        fontSize: "19px"
    },
    imageCenter: {
      textAlign: "center"
    },
    content: {
        padding: "12px 38px 83px",
        fontSize: "15px",
        color: "#000000DE",
        fontWeight: "400"
    },
    successWithImg: {
        padding: "51px 38px 55px",
    }
}));

const IntroDialog = (props) => {
    const { onClose, open, title, content } = props;
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog disableEscapeKeyDown={true} disableBackdropClick={true} onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
            <DialogContent className={classes.dialog}>
                <DialogTitle id="simple-dialog-title" className={classes.close}>
                    <i className="fas fa-times font-weight-600" style={{cursor: "pointer"}} onClick={() => onClose()}/>
                </DialogTitle>
                <Typography className={title ? classes.title : classes.imageCenter}>
                    {title ? title : (
                        <i className="fas fa-check-square font-size-90" />
                    )}
                </Typography>
                <Typography className={title? classes.content : classes.successWithImg}>
                    {content}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

IntroDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default IntroDialog;