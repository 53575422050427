import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import AuthMiddleware from "./routes/middleware/AuthMiddleware";
import NonAuthMiddleWare from "./routes/middleware/NonAuthMiddleware";
import GlobalMiddleware from "./routes/middleware/GlobalMiddleware";

import PageNoteFound from "./components/Common/pages-404";
import VerticalLayout from "./components/VerticalLayout";
import AuthLayout from "./components/Layouts/AuthLayout";
import NonHeaderLayout from "./components/Layouts/NonHeaderLayout";
import {authRoutes, userRoutes} from "./routes/allRoutes";
import TermsOfUse from "./pages/PublicPages/TermsOfUse";
import MobileTerms from "./pages/PublicPages/MobileTermsOfUse";
import MobileGlossary from "./pages/PublicPages/MobileGlossary";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import "./assets/scss/theme.scss";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F96638'
        }
    }
});

function App() {
    return (
        <Router>
            <MuiThemeProvider theme={theme}>
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <NonAuthMiddleWare
                            path={route.path}
                            layout={VerticalLayout}
                            component={route.component}
                            key={idx}
                        />
                    ))}
                    {userRoutes.map((route, idx) => (
                        <AuthMiddleware
                            path={route.path}
                            layout={AuthLayout}
                            exact={route.exact}
                            component={route.component}
                            key={idx}
                        />
                    ))}
                    <GlobalMiddleware
                        path="/about"
                        layout={AuthLayout}
                        exact={true}
                        key={"About"}
                        component={TermsOfUse}/>
                    <GlobalMiddleware
                        path="/terms_of_use"
                        layout={VerticalLayout}
                        exact={true}
                        key={"TermsOfUse"}
                        component={TermsOfUse}/>
                    <GlobalMiddleware
                        path="/mobile_terms_of_use"
                        layout={NonHeaderLayout}
                        exact={true}
                        key={"MobileTermsOfUse"}
                        component={MobileTerms}
                    />
                    <GlobalMiddleware
                        path="/mobile_glossary"
                        layout={NonHeaderLayout}
                        exact={true}
                        key={"MobileGlossary"}
                        component={MobileGlossary}
                    />
                    <Route path="*" component={PageNoteFound}/>
                </Switch>
            </MuiThemeProvider>
        </Router>
    );
}

export default App;