import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import ListPlus from "../../assets/images/ListPlus.png";
import List from "../../assets/images/List.png";
import Farmers from "../../assets/images/farmers.png";
import Calculator from "../../assets/images/Calculator.png";
import {Link} from "react-router-dom";

const data = [
    {image: ListPlus, text: "Add a plan", link: "/add_new_plan"},
    {image: List, text: "Dosing plans", link: "/dosing_plans"},
    {image: Farmers, text: "Farmers", link: "/farmers"},
    {image: Calculator, text: "Dose calculator", link: "/dosing_calculator"}
];

const list = () => {
    return data.map((item, index) => {
        return (
            <Col className="list-board align-items-center mx-auto" xs={12} key={index}>
                <Link to={item.link} >
                <img src={item.image} alt={"list"} />
                <span className="list-text">
                    {item.text}
                </span>
                </Link>
            </Col>
        )
    })
}

const BoardItem = () => {
    return (
        <Fragment>
            {list()}
        </Fragment>
    )
}

export default BoardItem;
