import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '365px',
    margin: "auto",
  },
  dialog: {
    boxShadow: "unset",
    padding: "15px 12px 35px",
    textAlign: "center",
  },
  close: {
    padding: "6px 12px"
  },
  title: {
    padding: "12px 38px",
    fontSize: "19px"
  },
  imageCenter: {
    textAlign: "center"
  },
  content: {
    padding: "12px",
    fontSize: "15px",
    color: "#000000DE",
    fontWeight: "400"
  },
}));

const ImageYesOrNoModal = (props) => {
  const { onClose, open, image, content } = props;
  const classes = useStyles();

  const handleClose = (val=1) => {
    onClose(val)
  };

  return (
    <Dialog disableBackdropClick={true} disableEscapeKeyDown={true} onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent className={classes.dialog}>
        <img src={image} alt="img" className="mt-2" />
        <Typography className={`${classes.content} font-size-17`}>
          {content}
        </Typography>
        <button className="btn mr-3" onClick={() => handleClose(1)}>
          <h5 style={{color: "#F96638"}} className="mb-0">NO</h5>
        </button>
        <button className="btn ml-3" onClick={() => handleClose(2)}>
          <h5 style={{color: "#F96638"}} className="mb-0">YES</h5>
        </button>
      </DialogContent>
    </Dialog>
  );
}

ImageYesOrNoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default ImageYesOrNoModal;