import React from "react";
import Glossary from "../pages/PublicPages/Glossary";
import Dashboard from "../pages/Dashboard";
import Calculator from "../pages/Calculator";
import AddNewPlan from "../pages/DosingPlans/AddNewPlan";
import AddNewFarmer from "../pages/Farmers/AddNewFarmer";
import DosingReview from "../pages/DosingPlans/reviewPlan";
import DosingViw from "../pages/DosingPlans/DosingView";
import SharedPlan from "../pages/DosingPlans/SharedPlan";

const userRoutes = [
    { path: "/dashboard", exact: true, component: Dashboard},
    { path: "/dosing_review/:uid", exact: true, component: DosingReview },
    { path: "/dosing_detail/:uid", exact: true, component: DosingViw },
    { path: "/glossary", component: Glossary },
    { path: "/dosing_calculator", component: Calculator },
    { path: "/", exact: true, component: AddNewPlan },
    { path: "/add_new_farmer", exact: true, component: AddNewFarmer },
    { path: "/", exact: true, component: AddNewPlan }
];

const authRoutes = [
    { path: "/glossary_detail", component: Glossary },
    { path: "/shared_plan/:uid", exact: true, component: SharedPlan}
];

export {userRoutes, authRoutes};