import React, { Component } from "react";
import AuthHeader from "./AuthHeader";
import Footer from "./Footer";

class Layout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div id="layout-wrapper">
                    <AuthHeader />
                    <div className="main-content">
                        {this.props.children}
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}
export default Layout
