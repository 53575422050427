import {SAVE_FARMER_INFO, SAVE_PLAN_DETAILS} from "../actionTypes";

const initialState = {
    farmerInfo: null,
    detailList: []
}

const farmersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_FARMER_INFO:
            return {
                ...state,
                farmerInfo: action.farmer
            }
        case SAVE_PLAN_DETAILS:
            return {
                ...state,
                detailList: action.details
            }
        default: {
            return state
        }
    }
}

export default farmersReducer;
