import {GET_FARMERS, GET_FARMERS_SUCCESS, GET_FARMERS_FAILED, NO_FARMER_WITH_SEARCH} from "../actionTypes";

const initialState = {
  isLoading: false,
  farmers: [],
  total: 0,
  noSearchResult: false
}

const farmersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FARMERS:
      return {
        ...state,
        noSearchResult: false,
        isLoading: true,
      }
    case GET_FARMERS_SUCCESS: {
      return {
        ...state,
        farmers: action.farmers,
        total: action.total
      }
    }
    case NO_FARMER_WITH_SEARCH: {
      return {
        ...state,
        noSearchResult: true
      }
    }
    default: {
      return state
    }
  }
}

export default farmersReducer;
