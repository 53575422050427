import { SAVE_FARMER_INFO, SAVE_PLAN_DETAILS } from "../actionTypes"

export const saveFarmerInfo = (farmer) => {
    return dispatch => {
        dispatch({type: SAVE_FARMER_INFO, farmer});
    }
}

export const savePlanDetails = (planDetails) => {
    return dispatch => {
        let params = []
        if (planDetails.length > 0) {
            planDetails.forEach((item, index) => {
                params.push({
                    id: item.id,
                    first_option: item.first_option,
                    second_option: item.second_option,
                    third_option: item.third_option,
                    notes: item.notes
                })
            })
        }
        dispatch({type: SAVE_PLAN_DETAILS, details: params});
    }
}
