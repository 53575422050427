import React from 'react';
import {Col, Container, Row} from "reactstrap";
import { useHistory } from 'react-router-dom';

const GlossaryContent = (props) => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            <h1 className="zoetis-color font-weight-bolder">DOSING<br />PLANNER</h1>
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <p className="font-size-16">
                                Find the treatment plan most suitable for your farmer
                            </p>
                        </Col>
                    </Row>
                    {props.hasBackButton && (
                        <div className="text-center">
                            <button className="btn email-back" onClick={() => history.goBack()}>
                                &lt;&nbsp;&nbsp;<u>BACK</u>
                            </button>
                        </div>
                    )}
                    <div className="text-center">
                        <h3 className="font-weight-bold" style={{color: "#727272"}}>Glossary and FAQs</h3>
                    </div>
                    <div className="glossary-border-top">
                        <h3 className="mt-3">
                            What is set stocking?
                        </h3>
                        <p className="font-size-15">
                            Animals must be turned out to clean pasture where no other animals have grazed that season. All animals in the group must be treated. No animals can be added to the group throughout the grazing season. The animals must remain on  the same pasture throughout the grazing season or alternatively can be moved to low risk pasture (i.e. silage aftermath).
                        </p>
                        <h3 className="mt-3">
                            Which classes of anthelmintics are used in cattle?
                        </h3>
                        <p className="font-size-15">
                            Group 1 or white wormers, also known as benzimidazoles (BZ).<br />
                            Group 2 or yellow wormers, also known as levamisoles (LV).<br />
                            Group 3 or clear wormers, also known as macrocyclic lactones (MLs).<br />
                        </p>
                        <h3 className="mt-3">
                            What is FEC?
                        </h3>
                        <div className="font-size-15" style={{color: "#212121"}}>
                            FEC= Faecal Egg Count<br />
                            FEC monitoring provides information about the stomach and gutworm status of a herd of cattle and can help in the decision about the need for treatment with anthelmintics.<br />
                            To take a good sample for FEC monitoring:<br />
                            <ul className="ul-padding-start">
                                <li>
                                    Sample at least 10 animals in the group.
                                </li>
                                <li>
                                    Animals should be healthy and have full access to pasture and/or feed prior to collection.
                                </li>
                                <li>
                                    Samples should be fresh when collected and kept cool (not frozen) in an airtight container or plastic bag.
                                </li>
                                <li>
                                    Identify the sample with the animal’s tag number.
                                </li>
                                <li>
                                    Deliver samples within 48 hours to the laboratory.
                                </li>
                            </ul>
                        </div>
                        <h3 className="mt-3">
                            What is a positive FEC?
                        </h3>
                        <p className="font-size-15">
                            If grazing calves have FECs > 200 eggs per gram and the faecal samples have been collected appropriately, treatment is likely to be justified.
                        </p>

                        <h3 className="mt-3">
                            How do you work out the DLWG?
                        </h3>
                        <p className="font-size-15">
                            DLWG=Daily Live Weight Gain<br />
                            To work out DLWG, record weight at two different timepoints.<br />
                            Your DLWG can then be worked out with the following formula:<br />
                            <br />
                            DLWG equals: (Latest weight recording - earliest recording) divided by (Number of days in between weight recordings)
                            <br /><br />
                            Dairy heifers are expected to grow 0.7-0.8kg per day, on average, to meet targets for breeding and calving. Heifers growing less than 0.7kg per day, could be suffering from a worm burden. Target DLWG for growing beef cattle is in the region of 0.8-1.2 kg and may vary depending on cattle type, system and target market.
                        </p>

                        <h3 className="mt-3">
                            What is FECRT?
                        </h3>
                        <div className="font-size-15" style={{color: "#212121"}}>
                            FECRT= Faecal Egg Count Reduction Test can help to determine the effectiveness of a wormer.<br />
                            To perform a FECRT:<br />
                            <ul className="ul-padding-start">
                                <li>
                                    Ensure the animals are weighed and the dose is administered with a properly calibrated dosing gun.
                                </li>
                                <li>
                                    Collect faeces of 10 animals at the time of dosing and then again from the same animals at the following time periods after treatment.
                                </li>
                            </ul>
                            <ul className="ul-padding-start-40 angle-right">
                                <li>
                                    Levamisole (LV): 7 days
                                </li>
                                <li>
                                    Benzimidazole (BZ): 14 days
                                </li>
                                <li>
                                    Macrocyclic Lactones (MLs): 14 days
                                </li>
                            </ul>
                            A reduction in FEC of &lt;95% of the pre-treatment is suggestive of a lack of wormer efficacy if the correct dosing procedure has been carried out.
                        </div>
                        <h3 className="mt-3">
                            What is PHD?
                        </h3>
                        <p className="font-size-15">
                            PHD=Pre-Housing Dose<br />
                            Animals treated with either Dectomax or Cydectin Pour-On within five weeks of housing will not require a further dose at housing.<br />
                        </p>
                        <h3 className="mt-3">
                            Persistency of Zoetis wormers
                        </h3>
                        <div className="table-responsive">
                            <table className="table glossary-table">
                                <thead>
                                <tr>
                                    <th>
                                        Worm species
                                    </th>
                                    <th>
                                        Ostertagia
                                    </th>
                                    <th>
                                        Dictyocaulus
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        Cydectin 1% injection
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                    <td>
                                        6 weeks
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cydectin Pour-On
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                    <td>
                                        6 weeks
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cydectin TriclaMox Pour-On
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cydectin 10% Long-acting injection
                                    </td>
                                    <td>
                                        120 days
                                    </td>
                                    <td>
                                        120 days
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dectomax 10 mg/ml injection
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Dectomax Pour-On
                                    </td>
                                    <td>
                                        5 weeks
                                    </td>
                                    <td>
                                        6 weeks
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <h3 className="mt-3">
                            Meat withdrawal periods (cattle):
                        </h3>
                        <div className="font-size-15" style={{color: "#212121"}}>
                            <ul className="ul-padding-start">
                                <li>Cydectin 1% injection: 65 days</li>
                                <li>Cydectin Pour-On: 14 days</li>
                                <li>Cydectin TriclaMox Pour-On: 143 days</li>
                                <li>Cydectin 10% Long Acting injection: 108 days</li>
                                <li>Dectomax injection: 70 days</li>
                                <li>Dectomax Pour-On: 35 days</li>
                            </ul>
                        </div>
                        <p className="font-size-15">
                            <br />
                            All Cydectin® products contain moxidectin.
                            Cydectin® TriclaMox Pour-On Solution for Cattle contains 5 mg/ml moxidectin and 200 mg/ml triclabendazole.
                            All Dectomax® products contain doramectin. Legal category POM.
                            <br />
                            For further information please check the SPC or contact Zoetis (01) 2569800. <a href="https://www.zoetis.ie" target="_blank">www.zoetis.ie</a>.
                            <br />
                            Use medicines responsibly. <a href="https://www.apha.ie" target="_blank">www.apha.ie</a>.
                        </p>
                        {props.hasBackButton && (
                            <div className="text-center mt-5">
                                <button className="btn email-back" onClick={() => history.goBack()}>
                                    &lt;&nbsp;&nbsp;<u>BACK</u>
                                </button>
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default GlossaryContent;