import React, {useState, useRef} from "react";
import Logo from "../../assets/images/logo.png";

import {makeStyles} from '@material-ui/core/styles';

import AddPlan from "../../assets/images/menuIcons/add_plan.png";
import DosingPlan from "../../assets/images/menuIcons/dosing_plan.png";
import Farmers from "../../assets/images/menuIcons/farmers.png";
import Calculator from "../../assets/images/menuIcons/calculator.png";
import Glossary from "../../assets/images/menuIcons/glossary.png";
import Details from "../../assets/images/menuIcons/my_details.png";
import About from "../../assets/images/menuIcons/about.png";
import {Link} from "react-router-dom";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';

import {connect} from "react-redux";

import {Container} from "reactstrap";

const menuItems = [
  {image: AddPlan, title: "Add a plan", link: "/"},
  {image: Calculator, title: "Dose calculator", link: "/dosing_calculator"},
  {image: Glossary, title: "Glossary", link: "/glossary"},
  {image: About, title: "About the app", link: "/about"}
]
const useStyles = makeStyles({
  root: {
    marginTop: "60px",
  },
  list: {
    width: 300,
    top: "60px"
  },
  fullList: {
    width: 'auto',
  },
  list_button: {
    padding: "5% 15%",
    fontSize: "16px"
  },
  drawer: {
    top: "60px"
  }
});

const AuthHeader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={handleToggle}
      onKeyDown={handleToggle}
    >
      <List style={{padding: "35px 5px"}}>
        {menuItems.map((item, index) => (
          <Link to={item.link} key={index}>
            <ListItem button className={classes.list_button}>
              <div>
                                <span className="menu-img">
                                    <img src={item.image} alt="add_a_plan" width={18}/>
                                </span>
                <span className="menu-text">{item.title}</span>
              </div>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const signOut = (e) => {
    e.preventDefault()
    props.signOutUser()
      .then(res => {
        window.location.href = "/login"
      })
      .catch(err => {
        throw err
      })
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container>
            <div className="d-flex float-left menu-bars">
              <span style={{marginTop: '2px', cursor: "pointer"}}>
                  <i className={!open ? "fas fa-bars" : "fas fa-times"}
                     ref={anchorRef}
                     aria-controls={open ? 'menu-list-grow' : undefined}
                     aria-haspopup="true"
                     onClick={handleToggle}/>
              </span>
              <span className="ml-3 header-title">
                  DOSING PLANNER
              </span>
            </div>
            <div className="d-flex float-right">
              <img src={Logo} alt="" height="37"/>
            </div>
            <Drawer anchor={"left"} open={open} onClose={handleToggle} className={classes.root}>
              {list("left")}
            </Drawer>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
};

export default AuthHeader