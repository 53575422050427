const getHeaders = () => {
  const accessToken = localStorage.getItem("access-token")
  const client = localStorage.getItem("client")
  const uid = localStorage.getItem("uid")
  return {
    "access-token": accessToken,
    "client": client,
    "uid": uid,
  }
}

export {getHeaders}