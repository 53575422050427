import React from 'react';
import GlossaryContent from "../../components/Common/Glossary";

const MobileGlossary = () => {
    return (
        <React.Fragment>
            <GlossaryContent hasBackButton={false} />
        </React.Fragment>
    )
}
export default MobileGlossary;