import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import FarmerList from "./farmerReducer";
import DosingPlan from "./dosingPlanReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  farmers: FarmerList,
  dosingPlan: DosingPlan
});

export default rootReducer