import React from "react";
import {Redirect, Route} from "react-router-dom";

const NonAuthMiddleWare = ({
                            component: Component,
                            layout: Layout
                        }) => (
    <Route
        render={props => {

            return (
                <Layout>
                    {/*{!localStorage.getItem("access-token") ? <Component {...props} /> : <Redirect to='/' />}*/}
                  <Component {...props} />
                </Layout>
            );
        }}
    />
);

export default NonAuthMiddleWare;

