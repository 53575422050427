import React, { Component } from "react";

class NonHeaderLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div id="layout-wrapper">
                    <div className="main-content">
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default NonHeaderLayout
