import React from 'react';
import {Container} from "reactstrap";
import TermsOfUse from "../../components/Common/TermsOfUse";


const MobileTerms = (props) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <TermsOfUse hasBackButton={false} />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default MobileTerms;