import React, {Fragment, useState} from "react";
import {Row, Col} from "reactstrap";
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import SuccessDialog from "../Modal/SuccessModal";


const FarmerRegisterForm = (props) => {
  const [open, setOpen] = useState(false);

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const Schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('This field is required'),
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
    email: Yup.string().email().required('Email is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.values.name,
      address: props.values.address,
      mobile: props.values.mobile,
      email: props.values.email,
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      props.submitValues(values);
    },
  });


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'center'
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '38ch',
    },
  }));
  const classes = useStyles();
  const {errors, touched, handleSubmit, getFieldProps, isValid, dirty} = formik;
  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Row className={classes.root}>
            <Col xs={12} className="mb-3">
              <TextField
                id="outlined-disabled"
                label="Name"
                variant="outlined"
                {...getFieldProps('name')}
                color={"primary"}
                className={clsx(classes.margin, classes.textField)}
                placeholder={"Enter the farmer's name"}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <TextField
                id="outlined-disabled"
                label="Address"
                variant="outlined"
                color={"primary"}
                {...getFieldProps('address')}
                className={clsx(classes.margin, classes.textField)}
                placeholder={"Enter the farmer's address"}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <TextField
                id="outlined-disabled"
                label="Mobile number"
                variant="outlined"
                {...getFieldProps('mobile')}
                color={"primary"}
                className={clsx(classes.margin, classes.textField)}
                placeholder={"Enter the farmer's mobile number"}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <TextField
                id="outlined-disabled"
                label="Email address"
                variant="outlined"
                color={"primary"}
                {...getFieldProps('email')}
                className={clsx(classes.margin, classes.textField)}
                placeholder={"Enter the farmer's email address"}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {/*<p className="modal-launcher" onClick={() => setOpen(!open)}>What's this?</p>*/}
            </Col>
          </Row>
          <Row className="next-btn-wrapper justify-content-center">
            {!props.isEdit ? (
              <button type="button" onClick={() => window.location.href = "/farmers"}
                      className="btn back-btn mr-2">BACK</button>
            ) : (
              <button type="button" onClick={() => setOpen(!open)}
                      className="btn back-btn mr-2">BACK</button>
            )}
            <button disabled={!(isValid && dirty)} type="submit"
                    className={`${(isValid && dirty) ? "activated-next-btn" : "disabled-next-btn"} btn ml-2`}>
              {props.isEdit ? "Save" : "ADD FARMER"}
            </button>
          </Row>
        </Form>
      </FormikProvider>
      {open && (
        <SuccessDialog
          onClose={() => {
            setOpen(!open);
          }}
          open={open}
          yesOrNo={true}
          title={"Exit without saving changes?"}
          content={"YES"}/>
      )}
    </Fragment>
  )
}

export default FarmerRegisterForm;