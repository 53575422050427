import React, {Fragment, useState} from "react";
import {Row, Col} from "reactstrap";
import {makeStyles} from '@material-ui/core/styles';
import IntroDialog from "../Modal/IntroModal";
import clsx from 'clsx';
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FormHelperText} from "@material-ui/core"
import {Link} from "react-router-dom";

const dialogConst = {
    title: "VCI number",
    content: "Registration number with Veterinary Council of Ireland in numbers only e.g. 21321"
}

const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const dosingOptions = [
    {num: 2, name: "Cydectin Pour-On (1ml/10kg)", dose: 0.1},
    {num: 3, name: "Cydectin 1% injection (1ml/50kg)", dose: 0.02},
    {num: 4, name: "Cydectin 10% LA Injection (1ml/100kg)", dose: 0.01},
    {num: 5, name: "Cydectin TriclaMox Pour-On (1ml/10kg)", dose: 0.1},
    {num: 6, name: "Dectomax Pour-On(1ml/10kg)", dose: 0.1},
    {num: 7, name: "Dectomax Injection(1ml/50kg)", dose: 0.02},
    {num: 8, name: "Levamisole (LV)", dose: 1},
    {num: 9, name: "Benzimidazole (BZ)", dose: 1}
]


const StepTwo = (props) => {
    const [open, setOpen] = useState(false);
    const Schema = Yup.object().shape({
        totalAnimals: Yup.number().min(0).required('This field is required'),
        treatmentProduct: Yup.number()
            .test("validation", "Select one option", (value) => value !== 1)
            .required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            totalAnimals: props.values.totalAnimals,
            treatmentProduct: props.values.treatmentProduct,
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            props.submitStepOne(values);
        },
    });

    const handleClose = () => {
        setOpen(!open);
    }

    const selectOptions = () => {
        return dosingOptions.map((item, index) => {
            return (
                <MenuItem value={item.num} key={index}>{item.name}</MenuItem>
            )
        })
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            justifyContent: "center"
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '38ch',
        },
    }));

    const calculateDosage = (values) => {
        let value = dosingOptions.find(o => o.num === values.treatmentProduct);
        const quantity = (values.totalAnimals * props.expectedWeight * value.dose).toFixed(2);
        return quantity;
    }

    const classes = useStyles();
    const {errors, touched, handleSubmit, getFieldProps, isValid, dirty, setFieldValue, values} = formik;
    return (
        <Fragment>
            <FormikProvider value={formik}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Row className={`${classes.root} mb-4`}>
                            <p className="calculator-result">
                                Expected weight of animal on treatment date =  {props.expectedWeight} kg
                            </p>
                        </Row>
                        <Row className={classes.root}>
                            <div className="time-wrapper">
                                <TextField
                                    id="outlined-disabled"
                                    label="Total number of animals to be treated"
                                    type={"number"}
                                    variant="outlined"
                                    {...getFieldProps('totalAnimals')}
                                    color={"primary"}
                                    className={clsx(classes.margin, classes.textField)}
                                    error={Boolean(touched.totalAnimals && errors.totalAnimals)}
                                    helperText={touched.totalAnimals && errors.totalAnimals}
                                />
                            </div>
                            <div className="time-wrapper">
                                <FormControl
                                    variant="outlined"
                                    error={Boolean(touched.treatmentProduct && errors.treatmentProduct)}
                                    className={clsx(classes.margin, classes.textField)}>
                                    <InputLabel id="label">
                                        Treatment product
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        id="demo-simple-select-outlined"
                                        label="Treatment product"
                                        {...getFieldProps('treatmentProduct')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            Select from list
                                        </MenuItem>
                                        {selectOptions()}
                                    </Select>
                                    <FormHelperText>{touched.treatmentProduct && errors.treatmentProduct}</FormHelperText>
                                </FormControl>
                            </div>
                        </Row>
                        <Row className={`${classes.root}`}>
                            {isValid && dirty && values.treatmentProduct > 1 && values.treatmentProduct < 8 && values.totalAnimals > 0 && (
                                <p className="calculator-result">
                                    Total quantity of product required =  {calculateDosage(values)}ml
                                </p>
                            )}
                            {isValid && dirty && values.treatmentProduct > 7 && values.totalAnimals > 0 && (
                                <>
                                    <Col xs={12} className="calculator-result">
                                        Total weight of group = {numberWithCommas(Math.round((values.totalAnimals * props.expectedWeight)))}kg
                                    </Col>
                                    <Col xs={12} className="text-center font-size-18 color-black mt-3">
                                        Check the product dose rate to calculate the total quantity required.
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row className="next-btn-wrapper justify-content-center">
                            <button className="btn back-btn mr-2" onClick={() => props.goBack(1)}>BACK</button>
                            <button disabled={!((isValid && dirty) || props.isBack)} onClick={() => window.location.href = "/"}
                                    className={`${(isValid && dirty) || props.isBack ? "activated-next-btn" : "disabled-next-btn"} btn ml-4`}>
                                MAIN MENU
                            </button>
                        </Row>
                        <Row className={classes.root}>
                            <div className="glossary-link">
                                <Link to="/glossary" className="glossary-letter">VIEW GLOSSARY</Link>
                            </div>
                        </Row>
                        {open && (
                            <IntroDialog onClose={handleClose} open={open} title={dialogConst.title}
                                         content={dialogConst.content}/>
                        )}
                    </Form>
                </MuiPickersUtilsProvider>
            </FormikProvider>
        </Fragment>
    )
}

export default StepTwo;