import axios from 'axios';
import toastr from 'toastr';
import {getHeaders} from "../service/authHeader";

const paramPost = (url, data) => {
  const headers = getHeaders();
  const config = {
    method: 'post',
    url: url,
    headers,
    data: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const paramDownloadPost = (url, data) => {
  const headers = getHeaders();
  const config = {
    method: 'post',
    url: url,
    headers,
    responseType: 'blob',
    data: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const paramPut = (url, data) => {
  const headers = getHeaders();
  const config = {
    method: 'put',
    url: url,
    headers,
    data: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const noParamGet = (url) => {
  const headers = getHeaders();
  const config = {
    method: 'get',
    url: url,
    headers
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const paramNoHeaderGet = (url, data) => {
  const config = {
    method: 'get',
    url: url,
    params: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const paramGet = (url, data) => {
  const headers = getHeaders();
  const config = {
    method: 'get',
    url: url,
    headers,
    params: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

const noParamDelete = (url) => {
  const headers = getHeaders();
  const config = {
    method: 'delete',
    url: url,
    headers
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    return err.response
  });
}

export {paramPost, noParamGet, paramPut, paramGet, noParamDelete, paramDownloadPost, paramNoHeaderGet}
