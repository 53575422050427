import React from "react";
import {Redirect, Route} from "react-router-dom";

const GlobalMiddleware = ({component: Component, layout: Layout}) => (
  <Route
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default GlobalMiddleware;

