import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import moment from "moment";
import 'date-fns';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {noParamDelete, noParamGet, paramPost, paramPut} from "../../apiHelpers/commonApiHelper";
import ListImage from "../../assets/images/ListBig.png";
import DosingPageHead from "../../components/Common/DosingPageHead";
import {Link, useParams} from "react-router-dom";
import YesOrNoConfirmModal from "../../components/Modal/YesOrNoConfirmModal";
import SuccessDialog from "../../components/Modal/SuccessModal";
import ImageYesOrNoModal from "../../components/Modal/ImageYesOrNoModal";
import ListPng from "../../assets/images/List.png";
import PdfView from "../../components/dosing_plan/pdf_temp";
import EmailSendView from "../../components/dosing_plan/email_sender";
import {makeStyles} from '@material-ui/core/styles';
import {savePlanDetails} from "../../store/actions"
import {connect} from "react-redux";

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: "25%",
    },
}));
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);


const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{
                color: "white",
            }}>{children}</Typography>
        </MuiDialogTitle>
    );
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F96638'
        }
    }
});


const ReviewPlan = (props) => {
    const {uid} = useParams();
    const classes = useStyles();
    useEffect(() => {
        getOriginPlanData()
    }, [])

    //////// New progress ////////////
    const [creator, setCreator] = useState(null)
    const [modalData, setModalData] = useState(null);
    const [planInfo, setPlanInfo] = useState(null)
    const [planDetail, setPlanDetail] = useState([])
    const [originModalData, setOriginModalData] = useState(null)
    const [selectOption, setSelectOption] = useState(1)
    const [firstOption, setFirstOption] = useState(1)
    const [secondOption, setSecondOption] = useState(1)
    const [notes, setNotes] = useState("")
    const [imageModal, setImageModal] = useState(false)
    const [changeSave, setChangeSave] = useState(false)
    const [deleteDetail, setDeleteDetail] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [isPlanDeleted, setIsPlanDeleted] = useState(false)
    const [deletedValues, setDeletedValues] = useState([])
    const [exit, setExit] = useState(false)
    const [isPlanSaved, setIsPlanSaved] = useState(false)
    const [viewStatus, setViewStatus] = useState(1);
    const [isExit, setIsExit] = useState(false);

    const handleThirdSelectionChange = (e) => {
        setSelectOption(e.target.value)
    }
    const handleSecondSelectionChange = (e) => {
        setSecondOption(e.target.value)
    }
    const handleFirstSelectionChange = (e) => {
        setFirstOption(e.target.value)
    }
    const handleNotes = (e) => {
        setNotes(e.target.value)
    }

    const getOriginPlanData = () => {
        noParamGet('/api/v1/plans/' + uid)
        .then(
            res => {
                if (res.status === 200) {
                    const data = res.data;
                    props.savePlanDetails(data.plan_details)
                    setPlanDetail(data.plan_details)
                    setPlanInfo(data.plan)
                }
            }
        )
        .catch(err => {
            throw err
        })
    }

    const handleClose = () => {
        setOpen(false);
        const modal_note = !modalData.notes ? "" : modalData.notes
        if (modalData.third_option !== selectOption - 1 ||
            modalData.first_option !== firstOption - 1 ||
            modalData.second_option !== secondOption - 1 ||
            notes !== modal_note) {
            setChangeSave(!changeSave);
        }
    };

    const handleDelete = async (value) => {
        if (value) {
            setDeleteDetail(!deleteDetail)
            const index = await findDetailObjectIndex(modalData.id)
            noParamDelete(`/api/v1/plan_details/${modalData.id}`)
                .then(async (res) => {
                    if (res.status === 200) {
                        await removeObject(index)
                        await setDeletedValues(prevState => [...prevState, originModalData])
                    }
                })
                .catch((err) => {
                    throw err
                })
            setIsDeleted(!isDeleted)
        } else {
            setOpen(!open)
            setDeleteDetail(!deleteDetail)
        }
    }

    const handleExit = async (value) => {
        if (value) {
            setIsExit(true)
            saveAndExit()
        } else {
            await rollbackChanges()
            // window.location.href = "/dosing_plans"
        }
    }

    const handleUIChange = async (val) => {
        setChangeSave(!changeSave);
        if (val) {
            await updatePlanDetail(firstOption, secondOption, selectOption, notes, modalData.id)
            // const option = parseInt(selectOption)
            // if (option !== 1 && option !== modalData.third_option) {
            //     const matchingResult = await checkAndUpdateAllOptionChanges()
            //     if (matchingResult.length < 2) {
            //         await updateAllDetailOptions(option)
            //     }
            // }
            await setIsDeleted(!isDeleted)
        } else {
            setModalData(null)
            setOriginModalData(null)
        }
    }

    const checkAndUpdateAllOptionChanges = async () => {
        return planDetail.filter(item => item.third_option > 1);
    }

    const updateAllDetailOptions = async (option) => {
        let data = [...planDetail]
        data.forEach((item, index) => {
            data[index].third_option = option;
        })
        setPlanDetail(data)
    }

    const updatePlanDetail = async (firstOption, secondOption, selectOption, note, id) => {
        const index = await findDetailObjectIndex(id)
        let data = [...planDetail]
        data[index].notes = note;
        data[index].third_option = selectOption - 1;
        data[index].first_option = firstOption - 1;
        data[index].second_option = secondOption - 1;
        await paramPut(`/api/v1/plan_details/${id}`, data[index])
            .then(res => {
                if (res.status === 200) {
                    setPlanDetail(data)
                }
            })
            .catch(err => {
                throw err
            })
    }

    const removeObject = async (index) => {
        let data = [...planDetail]
        data.splice(index, 1);
        setPlanDetail(data)
    }

    const findDetailObjectIndex = async (id) => {
        return planDetail.findIndex(x => x.id === id);
    }

    const returnDescription = (option, text, prefix) => {
        if (option === -1) {
            if (text) {
                return text
            }
        } else {
            if (option === 0) {
                return "Select a product"
            } else {
                const values = text.split(";")
                return prefix ? `Treat with ${values[option - 1]}` : values[option - 1]
            }
        }
    }

    const handleDel = () => {
        setDeleteDetail(!deleteDetail)
        setOpen(!open)
    }

    const rollbackChanges = async () => {
        await paramPost('/api/v1/plans/update_plan', {plan_id: planInfo.id, details: props.detailList, deleted_items: deletedValues})
            .then(async (res) => {
                if (res.status === 200) {
                    setExit(false)
                    setTimeout(() => {
                        window.location.href = "/dosing_plans"
                    }, 1000)
                }
            })
            .catch(err => {
                throw err
            })
    }

    const saveAndExit = () => {
        setExit(false)
        setIsPlanSaved(!isPlanSaved)
    }

    const saveAndRemain = () => {
        setIsExit(false)
        saveAndExit()
    }

    const handleDeletePlan = (val) => {
        if (val === 1) {
            setImageModal(!imageModal)
        } else {
            noParamDelete(`/api/v1/plans/${planInfo.id}`)
                .then((res) => {
                    if (res.status === 200) {
                        setImageModal(!imageModal);
                        setIsPlanDeleted(!isPlanDeleted);
                    }
                })
                .catch((err) => {
                    throw err
                })
        }
    }

    const changeViewState = (value) => {
        setViewStatus(value)
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/shared_plan/${uid}`)
            .then(() => {
                setLinkOpen(true);
            })
            .catch((e) => {
                throw e
            });
    }

    const changeStatus = (val) => {
        setViewStatus(val)
    }
    ////////======End======//////////

    const [open, setOpen] = React.useState(false);
    const [linkOpen, setLinkOpen] = useState(false);

    const handleClickOpen = (index, content, type) => {
        setModalData(content);
        setOriginModalData(content);
        setSelectOption(content.third_option + 1);
        setFirstOption(content.first_option + 1);
        setSecondOption(content.second_option + 1);
        setNotes(content.notes === null ? "" : content.notes)
        setOpen(true);
    };


    const handleLinkClose = () => {
        setLinkOpen(false);
    };

    const weekNumber = (number) => {
        if(number === 0) {
            return "Turn-out"
        } else if(number !== 25) {
            return `Week ${number}`
        } else {
            return "PRE-HOUSING-DOSE"
        }
    }

    const cards = () => {
        if (planDetail.length > 0) {
            return planDetail.map((item, index) => {
                return (
                    <Card key={index} className="dosing-card" variant="outlined"
                          onClick={() => handleClickOpen(index + 1, item, 1)}
                    >
                        <CardContent className="dosing-card-content">
                            <div className="dosing-dates">
                                <span
                                    className="float-left dosing-week"> {weekNumber(item.week_number)}</span>
                                <span
                                    className="float-right dosing-date">{moment(item.dosing_date).format('D MMM YYYY')}</span>
                            </div>
                            <div className="dosing-content float-left">
                                <ul>
                                    <li>{returnDescription(item.first_option, item.first_desc, true)}</li>
                                    {item.second_desc && (
                                        <li>{returnDescription(item.second_option, item.second_desc, true)}</li>
                                    )}
                                    {item.third_desc && (
                                        <li>{returnDescription(item.third_option, item.third_desc, false)}</li>
                                    )}
                                    {item.notes && (
                                        <li>Notes: {item.notes}</li>
                                    )}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>
                )
            })
        }
    }

    return (
        <React.Fragment>
            {viewStatus === 1 && (
                <div className="page-content">
                    <Container>
                        <DosingPageHead image={ListImage} text={"Dosing plan"}/>
                        <Row xs={12} style={{marginTop: "-30px"}}>
                            <Col xs={12} className="font-size-20 mb-3 text-center">
                           <span className="font-weight-bold">
                               {planInfo && planInfo.farmer.name}
                           </span>
                                <span className="ml-3 secondary-color">
                                {planInfo && planInfo.farmer.address}
                            </span>
                            </Col>
                            <Col xs={12} className="font-size-20 mb-3 text-center">
                           <span className="font-weight-bold font-size-16">
                               Date: {planInfo && moment(planInfo.created_at).format('D MMMM YYYY')}
                           </span>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={12} className="text-center">
                                <div className="border-line mt-2 mb-3"></div>
                                <Fragment>
                                    <MuiThemeProvider theme={theme}>
                                        <React.Fragment>
                                            <div>
                                                <i className="mdi mdi-link" onClick={() => handleCopyLink()}/>
                                                <i className="mdi mdi-download" onClick={() => changeViewState(2)}/>
                                                <i className="mdi mdi-email-send-outline"
                                                   onClick={() => changeViewState(3)}/>
                                            </div>
                                            <Row className="justify-content-center">
                                                <div className="dosing-time-wrapper sm-mb-2">
                                                    <h5 className="font-weight-bold">Animal type: <span
                                                        className="font-weight-normal">{planInfo && planInfo.animal.name}</span>
                                                    </h5>
                                                    <h5 className="font-weight-bold">Turn-out date: <span
                                                        className="font-weight-normal">{planInfo && moment(planInfo.turn_out_date).format('D MMMM YYYY')}</span>
                                                    </h5>
                                                </div>
                                                <div className="dosing-time-wrapper sm-mt-2">
                                                    <h5 className="font-weight-bold">Grazing: <span
                                                        className="font-weight-normal">{planInfo && planInfo.grazing_type === 0 ? "First season" : "Second season"}</span>
                                                    </h5>
                                                    <h5 className="font-weight-bold">Expected housing date: <span
                                                        className="font-weight-normal">{planInfo && moment(planInfo.housing_date).format('D MMMM YYYY')}</span>
                                                    </h5>
                                                </div>
                                            </Row>
                                            <div className="mt-2 mb-4 warning-div">
                                                <p className="attention">Beware of the risk of hoose or lungworm. If
                                                    cattle show signs of hoose, e.g. coughing,</p>
                                                <p className="attention"> contact the practice immediately as additional
                                                    treatments may be required.</p>
                                            </div>
                                            <Row className="justify-content-center font-weight-bold">
                                                <p>
                                                    Click on an action card to edit
                                                </p>
                                            </Row>
                                            <Row className="justify-content-center card-wrapper">
                                                {cards()}
                                            </Row>
                                            <Row>
                                                <Col className="next-btn-wrapper" xs={12} md={6}>
                                                    <button className="btn back-btn float-md-right"
                                                            style={{width: "200px"}}
                                                            onClick={() => setExit(!exit)}>EXIT
                                                    </button>
                                                </Col>
                                                <Col className="next-btn-wrapper" xs={12} md={6}>
                                                    <button className="btn activated-next-btn float-md-left"
                                                            style={{width: "200px"}}
                                                            onClick={saveAndRemain}>
                                                        SAVE
                                                    </button>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    </MuiThemeProvider>
                                </Fragment>
                                <Row className="justify-content-center mt-3">
                                    <div className="glossary-link">
                                    <span style={{verticalAlign: "middle", color: "#BF0D3E", marginRight: "5px"}}>
                                      <i className="fas fa-times-circle font-size-20"/>
                                    </span>
                                        <Link to="#" className="glossary-letter"
                                              onClick={() => setImageModal(!imageModal)}>
                                            Delete plan details
                                        </Link>
                                    </div>
                                </Row>
                                <Row className="justify-content-center">
                                    <div className="glossary-link">
                                        <a href="/glossary" className="glossary-letter">VIEW GLOSSARY</a>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    {open && (
                        <Dialog classes={{paper: classes.paper}} maxWidth={"sm"} disableBackdropClick={true}
                                disableEscapeKeyDown={true} onClose={handleClose}
                                aria-labelledby="customized-dialog-title" open={open}>
                            <DialogTitle style={{background: "#FF6400", padding: "6px"}} id="customized-dialog-title"
                                         onClose={handleClose}>
                                Dosing plan action card
                            </DialogTitle>
                            <DialogContent className="pt-3 dosing-card-content">
                                <div className="dosing-dates">
                            <span
                                className="float-left dosing-week">{weekNumber(modalData.week_number)}</span>
                                    <span className="float-right dosing-date">
                                {moment(modalData.dosing_date).format('D MMM YYYY')}
                            </span>
                                </div>
                                <div className="dosing-content float-left width-100">
                                    <ol className="dosing-desc-modal-ol">
                                        {modalData.first_option === -1 ? (
                                            <li className="dosing-desc-modal-li">
                                                {modalData.first_desc}
                                            </li>
                                        ) : (
                                            <select value={firstOption} className="dosing-detail-select"
                                                    onChange={handleFirstSelectionChange}>
                                                <option value={1} disabled={true}>1. Select a product</option>
                                                {modalData.first_desc.split(";").map((item, index) => {
                                                    return (
                                                        <option key={index} value={index + 2}>1. Treat
                                                            with {item}</option>
                                                    )
                                                })}
                                            </select>
                                        )}
                                        {modalData.second_desc && (
                                            modalData.second_option === -1 ? (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.second_desc}
                                                </li>
                                            ) : (
                                                <select value={secondOption} className="dosing-detail-select"
                                                        onChange={handleSecondSelectionChange}>
                                                    <option value={1} disabled={true}>2. Select a product</option>
                                                    {modalData.second_desc.split(";").map((item, index) => {
                                                        return (
                                                            <option key={index} value={index + 2}>2. Treat
                                                                with {item}</option>
                                                        )
                                                    })}
                                                </select>
                                            )
                                        )}
                                        {modalData.third_desc && (
                                            modalData.third_option === -1 ? (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.third_desc}
                                                </li>
                                            ) : (
                                                <select value={selectOption} className="dosing-detail-select"
                                                        onChange={handleThirdSelectionChange}>
                                                    <option value={1} disabled={true}>3. Select a product</option>
                                                    {modalData.third_desc.split(";").map((item, index) => {
                                                        return (
                                                            <option key={index} value={index + 2}>3. {item}</option>
                                                        )
                                                    })}
                                                </select>
                                            )
                                        )}
                                    </ol>
                                    <textarea value={notes} onChange={handleNotes} className="dosing-detail-select"
                                              style={{padding: "5px"}} rows={3} placeholder="Notes">
                            </textarea>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {modalData.week_number !== 25 && (
                                    <button className="btn position-absolute left-0" onClick={handleDel}>
                                        <p className="mb-0" style={{color: "#585858"}}>
                                            <i className="mdi mdi-trash-can-outline mdii m-0"/> REMOVE FROM PLAN
                                        </p>
                                    </button>
                                )}
                                <button className="btn float-right" onClick={handleClose}>
                                    <h5 style={{color: "#FF6400"}} className="mb-0 font-size-14">CLOSE</h5>
                                </button>
                            </DialogActions>
                        </Dialog>
                    )}

                    <Dialog onClose={handleLinkClose} open={linkOpen}>
                        <DialogContent className="dosing-card-content">
                            <Typography gutterBottom style={{color: "#979797", marginTop: "1.5rem"}}>
                                Link copied to pasteboard
                            </Typography>
                        </DialogContent>
                        <DialogActions className="justify-content-center">
                            <button className="btn" onClick={handleLinkClose}>
                                <h5 style={{color: "#F96638"}}>OK</h5>
                            </button>
                        </DialogActions>
                    </Dialog>

                </div>
            )}

            {viewStatus === 2 && (
                <PdfView planDetail={planDetail} planInfo={planInfo} changeViewStatus={changeStatus}/>
            )}

            {viewStatus === 3 && (
                <EmailSendView planDetail={planDetail} planInfo={planInfo} changeViewStatus={changeStatus}/>
            )}

            {changeSave && (
                <YesOrNoConfirmModal
                    onClose={handleUIChange}
                    open={changeSave}
                    title={"Do you want to save changes?"}/>
            )}
            {exit && (
                <YesOrNoConfirmModal
                    onClose={handleExit}
                    open={exit}
                    title={"Do you want to save changes?"}/>
            )}
            {deleteDetail && (
                <YesOrNoConfirmModal
                    onClose={handleDelete}
                    open={deleteDetail}
                    title={"Are you sure you want to delete this action?"}/>
            )}
            {isDeleted && (
                <SuccessDialog
                    onClose={() => setIsDeleted(!isDeleted)}
                    open={isDeleted}
                    content={"OK"}
                    title={"Action card changes saved"}/>
            )}
            {isPlanSaved && (
                <SuccessDialog
                    onClose={() => {
                        setIsPlanSaved(!isPlanSaved);
                        if(isExit) {
                            setTimeout(() => {
                                window.location.href = "/dosing_plans"
                            }, 1000)
                        }
                    }}
                    open={isPlanSaved}
                    content={"OK"}
                    title={"Dosing plan saved"}/>
            )}
            {imageModal && (
                <ImageYesOrNoModal
                    onClose={handleDeletePlan}
                    open={imageModal}
                    image={ListPng}
                    content={"Are you sure you want to delete this plan?"}/>
            )}
            {isPlanDeleted && (
                <SuccessDialog
                    onClose={() => {
                        setIsPlanDeleted(!isPlanDeleted);
                        window.location.href = "/dosing_plans";
                    }}
                    open={isPlanDeleted}
                    title={"Dosing plan deleted"}
                    content={"OK"}/>
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const {detailList} = state.dosingPlan;
    return {detailList};
}
  

export default connect(mapStateToProps, {savePlanDetails})(ReviewPlan);