import React from 'react';
import Divider from "@material-ui/core/Divider";
import {useHistory} from 'react-router-dom';

const TermsOfUse = (props) => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div className="text-center">
                <h3 className="font-weight-bold" style={{color: "#585858"}}>About the app</h3>
            </div>
            <div className="glossary-border-top">
                <p className="font-size-17 mt-3 mb-5">
                    Welcome to Dosing Planner (the "App" in the following). This App was developed and is operated by
                    Zoetis Belgium S.A. (Irish Branch).<br /> The information provided in this app is intended for general
                    information and education and is not intended to substitute clinical judgement or the clinical
                    examination of an animal by a veterinarian.
                </p>
                {/*<p className="font-size-15">*/}
                {/*    Please read and review these Terms of Use carefully before accessing or using this app. By accessing*/}
                {/*    or using this app, you acknowledge that you have read, understood and agreed to these Terms of Use.*/}
                {/*    If you do not agree to the Terms of Use, you may not access or use the app. Zoetis may alter these*/}
                {/*    Terms of Use from time to time, so you should periodically check these Terms of Use as any changes*/}
                {/*    will take effect as soon as they are updated to this app.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Use of App*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Your access to and use of the information contained in the app is subject to these Terms of Use. You*/}
                {/*    agree to act lawfully at all times in using and accessing this app and you will not do anything*/}
                {/*    which damages, interferes with, disrupts access to, interrupts or impairs the functionality of this*/}
                {/*    app or inhibits other users' use and enjoyment of the app.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Content*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    You agree that access to and use of this app and its content is at your own risk. Zoetis may add,*/}
                {/*    alter or delete materials from this app at any time at its discretion. Zoetis will use reasonable*/}
                {/*    efforts to include accurate and up-to-date information on this app but gives no warranties,*/}
                {/*    undertakings or representations of any kind as to its accuracy, currency, quality, completeness or*/}
                {/*    fitness for purpose. Zoetis disclaims all warranties, express or implied to the fullest extent*/}
                {/*    permitted by law. Neither Zoetis, its affiliates, nor any party involved in creating, producing or*/}
                {/*    delivering this app shall be liable for any loss, damage or cost whatsoever, whether in contract,*/}
                {/*    tort (including negligence) or otherwise arising from reliance on information contained in this app,*/}
                {/*    access to, use of, or inability to use this app, or any errors or omissions in its content. This*/}
                {/*    limitation includes any loss, damage or cost caused by any viruses that infect your phone, software*/}
                {/*    or data.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Access*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    The information included in the app that can be accessed is intended solely as general information*/}
                {/*    about Zoetis Animal Health's activities in Ireland.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Privacy*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Zoetis respects the privacy of its app users. Please refer to our Privacy Policy which explains*/}
                {/*    users' rights and the basis on which we may use any personal data (including by using "cookies")*/}
                {/*    that is disclosed to us via this app.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Third Party Website and Links*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    This app may contain links or references to other websites maintained by third parties over whom we*/}
                {/*    have no control. Such links are provided merely as a convenience. Similarly, this app may be*/}
                {/*    accessed from third party links over which Zoetis has no control. Zoetis makes no warranties,*/}
                {/*    undertakings or representations of any kind as to the accuracy, currency, quality, completeness or*/}
                {/*    fitness for purpose of any information contained in such sources and shall have no liability for any*/}
                {/*    loss, damage or cost of any kind arising from such information. Inclusion of any third party link*/}
                {/*    does not imply an endorsement or recommendation by Zoetis.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Medical Information*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    This app may contain information relating to various medical conditions and their treatment. This*/}
                {/*    information is provided for general information only and is not meant to be a substitute to your*/}
                {/*    clinical judgement or the clinical examination of an animal.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Non-Confidential Information*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Subject to our Privacy Policy, any communication or other material that you send to Zoetis by*/}
                {/*    electronic mail or otherwise, such as any questions, comments and suggestions, is and will be deemed*/}
                {/*    to be non-confidential and Zoetis shall have no obligation of any kind with respect to it. Zoetis*/}
                {/*    shall be free to use any ideas, concepts, know-how or techniques contained in these communications*/}
                {/*    and materials for any purpose whatsoever, including but not limited to, developing, manufacturing*/}
                {/*    and marketing products. Zoetis is not liable for the accuracy, scientific or otherwise, or*/}
                {/*    completeness of any information, comments or opinions on this website that are expressed by third*/}
                {/*    parties to the Zoetis group.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Intellectual Property Rights*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Zoetis owns all copyright, patents, database rights, trade marks, designs, know-how and confidential*/}
                {/*    information (whether registered or not), and all other intellectual property rights existing in this*/}
                {/*    app and its contents. The contents of this app may only be copied for non-commercial individual*/}
                {/*    reference with all copyright or other proprietary notices retained, and thereafter may not be*/}
                {/*    recopied, reproduced or otherwise redistributed. Except as expressly permitted above, you may not*/}
                {/*    copy, display, download, distribute, modify, reproduce, republish or retransmit any information,*/}
                {/*    text or documents contained in this app or any part of it in any electronic medium or in hard copy,*/}
                {/*    or create any derivative work based on it, without express written consent from Zoetis. In addition,*/}
                {/*    the Zoetis name and logo are registered trademarks, and may not be used without Zoetis written*/}
                {/*    permission from Zoetis. The misuse of Zoetis trademarks or any other materials is prohibited and may*/}
                {/*    be in breach of copyright law, trademark law and/or other Irish laws. Please note that Zoetis*/}
                {/*    actively and aggressively enforces its intellectual property rights to the fullest extent of the*/}
                {/*    law.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Irish Law*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    This app and its contents are intended to comply with Irish laws. Although the information in this*/}
                {/*    app is accessible to users outside of Ireland, the information on the app is intended for use only*/}
                {/*    by residents of Ireland. Other countries may have laws, regulatory requirements and medical*/}
                {/*    practices that differ from those in Ireland. These Terms of Use and your use of the app shall be*/}
                {/*    governed by and construed in accordance with the laws of Ireland. The Irish courts shall have*/}
                {/*    exclusive jurisdiction over any disputes arising in connection with this app.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Miscellaneous*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    To the extent that any provisions of these Terms of Use are held to be illegal, invalid or*/}
                {/*    unenforceable, then such provisions shall be severed and deleted without affecting the*/}
                {/*    enforceability of the remaining provisions.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Copyright 2021 Zoetis Ireland Ltd. All rights reserved.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Privacy Policy*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    At Zoetis, the company behind Zoetis Dosing Planner webApp, we recognise the importance of*/}
                {/*    protecting the privacy of information collected about the users of Zoetis Dosing Planner webApp. We*/}
                {/*    are committed to respecting the privacy of our users. Below we set out how the data that you*/}
                {/*    disclose at this app will be held, what it will be used for, and details of our data protection*/}
                {/*    policy.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    We collect personally identifiable information, such as names, addresses, telephone numbers and*/}
                {/*    email addresses only when voluntarily provided by you when you register or otherwise voluntarily*/}
                {/*    submit information to us. We will, of course, only process this information in accordance with this*/}
                {/*    policy or as otherwise agreed to by you.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    We may also collect data from you by using "cookies", which are certain bits of information*/}
                {/*    deposited by this app on a visitor's phone. Cookies are sent back only to the servers that deposited*/}
                {/*    them when a visitor returns to that site. Cookies can tell us how and when pages in an app are*/}
                {/*    visited and by how many people. With the information we receive through this technology, we hope to*/}
                {/*    improve our applications. In addition, we gather information about you that is automatically*/}
                {/*    collected by our server, such as your IP address and domain name. We may use this information to*/}
                {/*    customise our offerings, to make information more readily accessible to you, and the app easier for*/}
                {/*    you to use. For more information about cookies, please see the Information Commissioner's website*/}
                {/*    home page. (This website is neither owned nor controlled by Zoetis. Zoetis is not responsible for*/}
                {/*    the content or services of this site).*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    By using this app, you agree that we may use the information you have given in the following way:*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Your information will be held on a database within or outside of the European Economic Area*/}
                {/*    (EEA).Zoetis Ireland of Cherrywood Business Park, 2nd Floor, Building 10, Loughlinstown, Co Dublin,*/}
                {/*    D18 T3Y1, Ireland, will collect your data as controller of the database and your details may be*/}
                {/*    accessible to and used by other Zoetis group companies. As Zoetis is a global organisation, your*/}
                {/*    information may be transferred within or outside of the EEA. However, we will always employ*/}
                {/*    appropriate technical security measures to protect your personal information and to ensure that it*/}
                {/*    is not accessed by unauthorised persons.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Your information may be used by Zoetis to provide you with printed and/or online publications,*/}
                {/*    telephone messages, SMS messages and/or email communications (if you have consented to receive these*/}
                {/*    communications from us as part of the registration process). These will contain tailored information*/}
                {/*    as part of the Zoetis Animal Health website programme.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Zoetis may also use your information to send you (including by email) other health-related*/}
                {/*    information which may relate to your animal’s health condition and/or may be of interest to you,*/}
                {/*    and/or to conduct market research into medical conditions and the usefulness of Zoetis health*/}
                {/*    information programmes (if you have consented to receive these communications from us).*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Zoetis respects the confidentiality of personal information. Only Zoetis, organisations working with*/}
                {/*    Zoetis in the administration of the above information, and Zoetis group companies will have access*/}
                {/*    to your information. We may use third party processors to conduct research, process data or maintain*/}
                {/*    our website, content, or programmes on our behalf, but your information will not be disclosed to any*/}
                {/*    other third parties such as outside mailing organisations. While it is unlikely, we may be required*/}
                {/*    to*/}
                {/*    disclose your information by a court order or to comply with other legal or regulatory requirements.*/}
                {/*    We will use reasonable efforts to notify you before we do so, unless we are legally restricted from*/}
                {/*    doing so.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Your information will be retained by Zoetis for the period of time that you are registered to one or*/}
                {/*    more of our health-related programmes. You will at any time have the right to access your*/}
                {/*    information, you can request that your information on the Zoetis database is amended/deleted, or you*/}
                {/*    can unsubscribe from any programme at any time by writing to us at: Zoetis Data Protection Officer,*/}
                {/*    Zoetis Ireland of Cherrywood Business Park, 2nd Floor, Building 10, , Loughlinstown, Co Dublin, D18*/}
                {/*    T3Y1, Ireland. Please mark the envelope “DSAR”. Alternatively, you can email us at*/}
                {/*    DataProtectionIE@Zoetis.com.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    We aim to deal with your request (including unsubscribing you from any programme) within ten working*/}
                {/*    days of its receipt.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    This Privacy Policy may be amended by us at any time. Please check this section periodically to*/}
                {/*    inform yourself of any changes.*/}
                {/*</p>*/}

                {/*<h2 className="mt-5 terms-h3-color">*/}
                {/*    COOKIE POLICY*/}
                {/*</h2>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Purpose of this cookie policy*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    This cookie policy applies to the Zoetis Dosing Planner webApp (the “webApp” in the following) and*/}
                {/*    aims to inform you about our use of cookies on the webApp. This policy helps us to provide you with*/}
                {/*    a good experience when you browse our webApp and also allows us to improve our App. This cookie*/}
                {/*    policy provides you with clear and comprehensive information about the cookies we use and the*/}
                {/*    purpose for using them.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    This policy does not govern the use of the webApp, nor the processing of personal data on this*/}
                {/*    webApp. To learn more about our terms of use, please consult [Terms of Use]; for more information*/}
                {/*    about how we process your personal data on this webApp, please click on [Privacy Policy].*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    What are cookies?*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    A cookie is a small text file that is placed on the browser or hard drive of your computer (or*/}
                {/*    similar device) by webApps that you visit. Cookies are typically used in order to make webApps work,*/}
                {/*    or work more efficiently, as well as to provide information to the owners of the particular webApp.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    What cookies do we use and why?*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Our webApp uses cookies to distinguish you from other users of our webApp. Cookies are used to*/}
                {/*    ensure that we give you the best experience on our webApp, and allow us to improve our webApp.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    We use the following types of cookies:*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    Strictly necessary cookies - These are cookies that are essential for the operation of our webApp.*/}
                {/*    These cookies remember information you have entered on forms when you navigate to different pages*/}
                {/*    during a web browser session, identify you as being logged in to our webApp, etc.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Performance cookies - These cookies allow us to recognise and count the number of visitors and to*/}
                {/*    see how visitors move around on our webApp when they are using it. These cookies help us to improve*/}
                {/*    the way the webApp works, for example, by ensuring that users are finding what they are looking for*/}
                {/*    easily. These cookies provide statistics on how our webApp is used, help us improve the webApp by*/}
                {/*    measuring any errors that occur, etc.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Functionality cookies - These are cookies that are used to recognise you when you return to our*/}
                {/*    webApp. This enables us to personalise our content for you, greet you by name, remember if we have*/}
                {/*    already asked you if you want to fill in a survey, and remember your preferences, for example your*/}
                {/*    choice of language or region.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Whether a cookie is considered as a 'first' or 'third party' cookie refers to the domain placing the*/}
                {/*    cookie. First-party cookies are those cookies set by a webApp that is being visited by the user at*/}
                {/*    the time (e.g. cookies placed by www.zoetis.com). Third-party cookies are cookies that are set by a*/}
                {/*    domain other than that of the webApp being visited by the user. If a user visits a webApp and*/}
                {/*    another entity sets a cookie through that webApp this would be a third-party cookie (e.g. Google*/}
                {/*    Analytics).*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Whether a cookie is considered as a 'persistent' or 'session' cookie refers to the duration of the*/}
                {/*    cookie. A persistent cookie remains on a user's device for the period of time specified in that*/}
                {/*    cookie, and is activated each time that said user visits the webApp that created the concerned*/}
                {/*    cookie. A session cookie allows to link the actions of a user during a browser session. A session*/}
                {/*    starts when a user opens a browser window and ends when the browser window is closed. Once the*/}
                {/*    browser is closed, all session cookies are deleted.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    How to manage cookies?*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    You block cookies by activating the setting on your browser that allows you to refuse the setting of*/}
                {/*    all or some cookies. These settings are usually found in the 'options' or 'preferences' menu of your*/}
                {/*    internet browser. However, if you use your browser settings to block all cookies (including strictly*/}
                {/*    necessary cookies) you may not be able to access all or parts of the webApp.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Once you have given us your consent to the use of cookies, we will store a cookie on your computer*/}
                {/*    or device to remember this for next time. If you wish to withdraw your consent at any time, you will*/}
                {/*    need to delete your cookies using your internet browser settings.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    For more details the following links may be helpful:*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Cookie settings in Internet Explorer*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Cookie settings in Firefox*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Cookie settings in Chrome*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    Cookie settings in Safari*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    More information on the use of cookies can be found on the webApp www.allaboutcookies.org, or your*/}
                {/*    may always contact is in this regard: privacy@zoetis.com.*/}
                {/*</p>*/}

                {/*<h3 className="mt-3 terms-h3-color">*/}
                {/*    Update*/}
                {/*</h3>*/}
                {/*<p className="font-size-15">*/}
                {/*    We reserve the right to modify this cookie policy from time to time. It is your responsibility to*/}
                {/*    regularly verify whether you have read and consented to the latest version of the applicable cookie*/}
                {/*    policy.*/}
                {/*</p>*/}
                {/*<p className="font-size-15">*/}
                {/*    This Cookie Policy was last updated on January 2015*/}
                {/*</p>*/}
                {props.hasBackButton && (
                    <div style={{visibility: "hidden"}}>
                        <Divider style={{marginTop: "10%"}}/>
                        <div className="text-center mt-5">
                            <button className="btn email-back" onClick={() => history.goBack()}>
                                &lt;&nbsp;&nbsp;<u>BACK</u>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}
export default TermsOfUse;