import React, {Fragment} from "react";
import {Container, Row} from "reactstrap";
import BoardItem from "../components/Common/BoardItem";


const Dashboard = (props) => {
    const signOut = (e) => {
        e.preventDefault()
        props.signOutUser()
            .then(res => {
                window.location.href = "/login"
            })
            .catch(err => {
                throw err
            })
    }
    return (
        <Fragment>
            <div className="page-content">
                <Container>
                    <Row className="mt-4 justify-content-center" style={{display: "unset"}}>
                        <BoardItem />
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default Dashboard