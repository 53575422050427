import React from "react";
import {Redirect, Route} from "react-router-dom";

const AuthMiddleware = ({
    component: Component,
    layout: Layout,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            return (
                <Layout>
                    {/*{localStorage.getItem("access-token") ? <Component {...props} /> : <Redirect to='/login' />}*/}
                  <Component {...props} />
                </Layout>
            );
        }}
    />
);

export default AuthMiddleware;
