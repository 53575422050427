import React  from "react";
import Logo from "../../assets/images/zoe_log.png";
import Divider from "@material-ui/core/Divider";
import { Container } from "reactstrap";
import moment from "moment";

const Footer = () => {
    return (
        <React.Fragment>
            <footer>
                <div>
                    <Container className="mb-3">
                        <Divider className="mb-4" />
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="">
                                    <img src={Logo} alt="" height="37" />
                                    <p className="mb-0 font-size-10 footer-copy">
                                        Copyright © Zoetis 2021-{moment().format("YYYY")} All rights reserved.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="position-absolute footer-terms">
                                    <p className="mr-2 mb-0">
                                        <a href="https://www2.zoetis.ie/privacy-policy" target="_blank">Privacy Policy</a>
                                    </p>
                                    <p className="mb-0">
                                        <a href="https://www2.zoetis.ie/terms-of-use" target="_blank">Terms of Use</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
