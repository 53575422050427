import React, { useState, Fragment } from 'react';
import { Container, Row, Col} from "reactstrap";
import moment from "moment";
import 'date-fns';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {paramDownloadPost} from "../../apiHelpers/commonApiHelper";
import SuccessDialog from "../Modal/SuccessModal";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F96638'
        }
    }
});

const weekNumber = (number) => {
    if(number === 0) {
        return "Turn-out"
    } else if(number !== 25) {
        return `Week ${number}`
    } else {
        return "PRE-HOUSING-DOSE"
    }
}

const returnDescription = (option, text, prefix) => {
    if (option === -1) {
        if (text) {
            return text
        }
    } else {
        if (option === 0) {
            return "Select a product"
        } else {
            const values = text.split(";")
            return prefix ? `Treat with ${values[option - 1]}` : values[option - 1]
        }
    }
}

const pdfView = React.forwardRef((props, ref) => {
    const [isDownloaded, setIsDownloaded] = useState(false)
    const cards = () => {
        if (props.planDetail.length > 0) {
            return props.planDetail.map((item, index) => {
                return (
                    <Card key={index} className="dosing-pdf-card" variant="outlined">
                        <CardContent className="dosing-card-content">
                            <div className="dosing-dates">
                                <span
                                    className="float-left dosing-week"> {weekNumber(item.week_number)}</span>
                                <span
                                    className="float-right dosing-date">{moment(item.dosing_date).format('D MMM YYYY')}</span>
                            </div>
                            <div className="dosing-content float-left">
                                <ul>
                                    <li>{returnDescription(item.first_option, item.first_desc, true)}</li>
                                    {item.second_desc && (
                                        <li>{returnDescription(item.second_option, item.second_desc, true)}</li>
                                    )}
                                    {item.third_desc && (
                                        <li>{returnDescription(item.third_option, item.third_desc, false)}</li>
                                    )}
                                    {item.notes && (
                                        <li>Notes: {item.notes}</li>
                                    )}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>
                )
            })
        }
    }

    const optionText = (option, optionOne, optionTwo) => {
        const optionVal = parseInt(option)
        if (optionVal === 1) {
            return "Select a product"
        } else if (optionVal === 2) {
            return optionOne
        } else {
            return optionTwo
        }
    }

    const handlePrint = (val) => {
        paramDownloadPost("/api/v1/plans/generate_pdf", {uid: props.planInfo.uid})
            .then(response => {
                const blob = URL.createObjectURL(response.data);
                if(val === 2) {
                    window.open(blob, '_blank');
                } else {
                    const link = document.createElement('a');
                    link.href = blob;
                    link.download = "dosing_plan" + ".pdf";
                    link.click();
                    setTimeout(function () {
                            window.URL.revokeObjectURL(blob);
                        }
                        , 100)
                    setIsDownloaded(!isDownloaded)
                }
            })
            .catch((err) => {
                throw err
            });
    }

    return (
        <React.Fragment>
            <div className="page-content" ref={ref}>
                <Container>
                    <Row style={{marginTop: "50px"}}>
                        <Col xs={12} className="text-center font-size-36 font-weight-bold">
                            Dosing plan
                        </Col>
                    </Row>
                    <Row className="">
                        <Col xs={12} className="text-center">
                            <div className="border-line mt-2 mb-3"></div>
                            <Fragment>
                                <MuiThemeProvider theme={theme}>
                                    <React.Fragment>
                                        <Row className="justify-content-center">
                                            <div className="dosing-time-wrapper sm-mb-2">
                                                <h5 className="font-weight-bold">Animal type: <span
                                                    className="font-weight-normal">{props.planInfo && props.planInfo.animal.name}</span>
                                                </h5>
                                                <h5 className="font-weight-bold">Turn-out date: <span
                                                    className="font-weight-normal">{props.planInfo && moment(props.planInfo.turn_out_date).format('D MMMM YYYY')}</span>
                                                </h5>
                                            </div>
                                            <div className="dosing-time-wrapper sm-mt-2">
                                                <h5 className="font-weight-bold">Grazing: <span
                                                    className="font-weight-normal">{props.planInfo && props.planInfo.grazing_type === 0 ? "First season" : "Second season"}</span>
                                                </h5>
                                                <h5 className="font-weight-bold">Expected housing date: <span
                                                    className="font-weight-normal">{props.planInfo && moment(props.planInfo.housing_date).format('D MMMM YYYY')}</span>
                                                </h5>
                                            </div>
                                        </Row>
                                        <div className="mt-2 mb-4 warning-div">
                                            <p className="attention">Beware of the risk of hoose or lungworm. If
                                                cattle show signs of hoose, e.g. coughing,</p>
                                            <p className="attention"> contact the practice immediately as additional
                                                treatments may be required.</p>
                                        </div>
                                        <Row className="justify-content-center card-wrapper">
                                            {cards()}
                                        </Row>
                                        <Row>
                                            <Col className="next-btn-wrapper" xs={12} md={6}>
                                                <button className="btn activated-next-btn float-md-right print-btn"
                                                        style={{width: "200px"}}
                                                        onClick={() => handlePrint(2)}>
                                                    PRINT
                                                </button>
                                            </Col>
                                            <Col className="next-btn-wrapper" xs={12} md={6}>
                                                <button className="btn activated-next-btn float-md-left download-btn"
                                                        style={{width: "200px"}}
                                                        onClick={() => handlePrint(1)}>
                                                    DOWNLOAD
                                                </button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </MuiThemeProvider>
                            </Fragment>
                        </Col>
                    </Row>
                </Container>
            </div>

            {isDownloaded&& (
                <SuccessDialog
                    onClose={() => {
                        setIsDownloaded(!isDownloaded);
                        props.changeViewStatus(1);
                    }}
                    open={isDownloaded}
                    title={"File downloaded"}
                    content={"OK"}/>
            )}
        </React.Fragment>
    );
})

export default pdfView;
