import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import moment from "moment";
import 'date-fns';
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {paramNoHeaderGet} from "../../apiHelpers/commonApiHelper";
import ListImage from "../../assets/images/ListBig.png";
import DosingPageHead from "../../components/Common/DosingPageHead";
import PdfView from "../../components/dosing_plan/pdf_temp";
import EmailSendView from "../../components/dosing_plan/email_sender";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: "25%",
    },
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);


const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" style={{
                color: "white",
            }}>{children}</Typography>
        </MuiDialogTitle>
    );
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F96638'
        }
    }
});


const SharedPlan = (props) => {
    const classes = useStyles();
    const pathName = window.location.pathname;
    const uid = pathName.split("/")[2];
    useEffect(() => {
        paramNoHeaderGet('/api/v1/plans/shared_plan', {uid})
            .then(
                res => {
                    if (res.status === 200) {
                        const data = res.data;
                        setPlanDetail(data.plan_details)
                        setPlanInfo(data.plan)
                    }
                }
            )
            .catch(err => {
                throw err
            })
    }, [])

    //////// New progress ////////////
    const [modalData, setModalData] = useState(null);
    const [planInfo, setPlanInfo] = useState(null)
    const [planDetail, setPlanDetail] = useState([])
    const [selectOption, setSelectOption] = useState(1)
    const [notes, setNotes] = useState("")
    const [firstOption, setFirstOption] = useState(1)
    const [secondOption, setSecondOption] = useState(1)
    const [viewStatus, setViewStatus] = useState(1);

    const handleNotes = (e) => {
        setNotes(e.target.value)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const changeViewState = (value) => {
        setViewStatus(value)
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/shared_plan/${uid}` )
            .then(() => {
                setLinkOpen(true);
            })
            .catch((e) => {
                throw e
            });
    }

    const changeStatus = (val) => {
        setViewStatus(val)
    }
    ////////======End======//////////

    const [open, setOpen] = React.useState(false);
    const [linkOpen, setLinkOpen] = useState(false);

    const handleClickOpen = (index, content, type) => {
        setModalData(content);
        setSelectOption(content.third_option + 1);
        setFirstOption(content.first_option + 1);
        setSecondOption(content.second_option + 1);
        setNotes(content.notes === null ? "" : content.notes)
        setOpen(true);
    };


    const handleLinkClose = () => {
        setLinkOpen(false);
    };

    const weekNumber = (number) => {
        if(number === 0) {
            return "Turn-out"
        } else if(number !== 25) {
            return `Week ${number}`
        } else {
            return "PRE-HOUSING-DOSE"
        }
    }

    const returnDescription = (option, text, prefix) => {
        if (option === -1) {
            if (text) {
                return text
            }
        } else {
            if (option === 0) {
                return "Select a product"
            } else {
                const values = text.split(";")
                return prefix ? `Treat with ${values[option - 1]}` : values[option - 1]
            }
        }
    }

    const cards = () => {
        if (planDetail.length > 0) {
            return planDetail.map((item, index) => {
                return (
                    <Card key={index} className="dosing-card" variant="outlined"
                          onClick={() => handleClickOpen(index + 1, item, 1)}
                    >
                        <CardContent className="dosing-card-content">
                            <div className="dosing-dates">
                                <span
                                    className="float-left dosing-week"> {weekNumber(item.week_number)}</span>
                                <span
                                    className="float-right dosing-date">{moment(item.dosing_date).format('D MMM YYYY')}</span>
                            </div>
                            <div className="dosing-content float-left">
                                <ul>
                                    <li>{returnDescription(item.first_option, item.first_desc, true)}</li>
                                    {item.second_desc && (
                                        <li>{returnDescription(item.second_option, item.second_desc, true)}</li>
                                    )}
                                    {item.third_desc && (
                                        <li>{returnDescription(item.third_option, item.third_desc, false)}</li>
                                    )}
                                    {item.notes && (
                                        <li>Notes: {item.notes}</li>
                                    )}
                                </ul>
                            </div>
                        </CardContent>
                    </Card>
                )
            })
        }
    }

    return (
        <React.Fragment>
            {viewStatus === 1 && (
                <div className="page-content">
                    <Container>
                        <DosingPageHead image={ListImage} text={"Dosing plan"}/>
                        <Row className="">
                            <Col xs={12} className="text-center">
                                <div className="border-line mt-2 mb-3"></div>
                                <Fragment>
                                    <MuiThemeProvider theme={theme}>
                                        <React.Fragment>
                                            <div>
                                                <i className="mdi mdi-link" onClick={() => handleCopyLink()}/>
                                                <i className="mdi mdi-download" onClick={() => changeViewState(2)}/>
                                                <a href={`mailto:?subject=Dosing plan&body=${window.location.origin}/shared_plan/${uid}`}>
                                                    <i className="mdi mdi-email-send-outline" />
                                                </a>
                                            </div>
                                            <Row className="justify-content-center">
                                                <div className="dosing-time-wrapper sm-mb-2">
                                                    <h5 className="font-weight-bold">Animal type: <span
                                                        className="font-weight-normal">{planInfo && planInfo.animal.name}</span>
                                                    </h5>
                                                    <h5 className="font-weight-bold">Turn-out date: <span
                                                        className="font-weight-normal">{planInfo && moment(planInfo.turn_out_date).format('D MMMM YYYY')}</span>
                                                    </h5>
                                                </div>
                                                <div className="dosing-time-wrapper sm-mt-2">
                                                    <h5 className="font-weight-bold">Grazing: <span
                                                        className="font-weight-normal">{planInfo && planInfo.grazing_type === 0 ? "First season" : "Second season"}</span>
                                                    </h5>
                                                    <h5 className="font-weight-bold">Expected housing date: <span
                                                        className="font-weight-normal">{planInfo && moment(planInfo.housing_date).format('D MMMM YYYY')}</span>
                                                    </h5>
                                                </div>
                                            </Row>
                                            <div className="mt-2 mb-4 warning-div">
                                                <p className="attention">Beware of the risk of hoose or lungworm. If
                                                    cattle show signs of hoose, e.g. coughing,</p>
                                                <p className="attention"> contact the practice immediately as additional
                                                    treatments may be required.</p>
                                            </div>
                                            <Row className="justify-content-center font-weight-bold">
                                                <p>
                                                    Click on an action card to view
                                                </p>
                                            </Row>
                                            <Row className="justify-content-center card-wrapper">
                                                {cards()}
                                            </Row>
                                        </React.Fragment>
                                    </MuiThemeProvider>
                                </Fragment>
                                <Row className="justify-content-center">
                                    <div className="glossary-link">
                                        <a href="/glossary_detail" className="glossary-letter">VIEW GLOSSARY</a>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    {open && (
                        <Dialog classes={{paper: classes.paper}} maxWidth={"sm"} disableBackdropClick={true}
                                disableEscapeKeyDown={true} onClose={handleClose}
                                aria-labelledby="customized-dialog-title" open={open}>
                            <DialogTitle style={{background: "#FF6400", padding: "6px"}} id="customized-dialog-title"
                                         onClose={handleClose}>
                                Dosing plan action card
                            </DialogTitle>
                            <DialogContent className="pt-3 dosing-card-content">
                                <div className="dosing-dates">
                            <span
                                className="float-left dosing-week">{weekNumber(modalData.week_number)}</span>
                                    <span className="float-right dosing-date">
                                {moment(modalData.dosing_date).format('D MMM YYYY')}
                            </span>
                                </div>
                                <div className="dosing-content float-left width-100">
                                    <ol className="dosing-desc-modal-ol">
                                        {modalData.first_option === -1 ? (
                                            <li className="dosing-desc-modal-li">
                                                {modalData.first_desc}
                                            </li>
                                        ) : (
                                            <li className="dosing-desc-modal-li">
                                                {modalData.first_desc.split(";")[firstOption - 2] || "Select a product"}
                                            </li>
                                        )}
                                        {modalData.second_desc && (
                                            modalData.second_option === -1 ? (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.second_desc}
                                                </li>
                                            ) : (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.second_desc.split(";")[secondOption - 2] || "Select a product"}
                                                </li>
                                            )
                                        )}
                                        {modalData.third_desc && (
                                            modalData.third_option === -1 ? (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.third_desc}
                                                </li>
                                            ) : (
                                                <li className="dosing-desc-modal-li">
                                                    {modalData.third_desc.split(";")[selectOption - 2] || "Select a product"}
                                                </li>
                                            )
                                        )}
                                    </ol>
                                    <textarea disabled={true} value={notes} onChange={handleNotes} className="dosing-detail-select"
                                              style={{padding: "5px"}} rows={3} placeholder="Notes">
                                    </textarea>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <button className="btn float-right" onClick={handleClose}>
                                    <h5 style={{color: "#FF6400"}} className="mb-0 font-size-14">CLOSE</h5>
                                </button>
                            </DialogActions>
                        </Dialog>
                    )}

                    <Dialog onClose={handleLinkClose} open={linkOpen}>
                        <DialogContent className="dosing-card-content">
                            <Typography gutterBottom style={{color: "#979797", marginTop: "1.5rem"}}>
                                Link copied to pasteboard
                            </Typography>
                        </DialogContent>
                        <DialogActions className="justify-content-center">
                            <button className="btn" onClick={handleLinkClose}>
                                <h5 style={{color: "#F96638"}}>OK</h5>
                            </button>
                        </DialogActions>
                    </Dialog>

                </div>
            )}

            {viewStatus === 2 && (
                <PdfView planDetail={planDetail} planInfo={planInfo} changeViewStatus={changeStatus} />
            )}

            {viewStatus === 3 && (
                <EmailSendView planDetail={planDetail} planInfo={planInfo} changeViewStatus={changeStatus} />
            )}
        </React.Fragment>
    );
}

export default SharedPlan;
