import React, {Fragment, useState} from "react";
import {Container, Row, Col} from "reactstrap";

import ImageActionModal from "../../components/Modal/ImageActionModal";
import AddFarmerImage from "../../assets/images/farmer.png";
import DosingPageHead from "../../components/Common/DosingPageHead";
import FarmerRegisterForm from "../../components/Farmers/FarmerRegisterForm";
import {Link} from "react-router-dom";
import Farmers from "../../assets/images/farmers.png";
import {paramPost} from "../../apiHelpers/commonApiHelper";

const AddNewFarmer = (props) => {
  const [successOpen, setSuccessOpen] = useState(false);

  const [values, setValues] = React.useState({
    name: '',
    address: '',
    mobile: '',
    email: '',
  });

  const handleValues = (values) => {
    setValues({...values});
    paramPost( '/api/v1/farmers', values)
      .then(res => {
        if (res.status === 201) {
          setSuccessOpen(!successOpen);
        }
      })
      .catch(err => {
        throw err
      })
  }

  return (
    <div className="page-content">
      <Container>
        <DosingPageHead image={AddFarmerImage} text={"Add a farmer"}/>
        <Row xs={12} className="mt-4">
          <Col xs={12} className="font-size-20 mb-3 text-center">
            Enter the farmer details
          </Col>
        </Row>
        <FarmerRegisterForm values={values} submitValues={handleValues}/>
        <Row className="justify-content-center">
          <div className="glossary-link">
            <Link to="/terms_of_use" className="glossary-letter">FIND OUT HOW WE USE THIS DATA</Link>
          </div>
        </Row>

        {successOpen && (
          <ImageActionModal
            onClose={() => {setSuccessOpen(!successOpen); window.location.href = "/farmers"}}
            open={successOpen}
            image={Farmers}
            content={"Farmer added successfully"}/>
        )}
      </Container>
    </div>
  )
}

export default AddNewFarmer;